import { FormInput, Icon, Loading, SectionHeader, Table } from 'components/shared';
import Pagination from 'components/shared/pagination/Pagination';
import routes from 'constants/routes';
import ListContext, { withListContext } from 'contexts/ListContext';
import { useIAM } from 'helpers/auth';
import { debounce } from 'lodash';
import * as ListModel from 'models/List';
import React, { useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

const ListsPage = () => {
  const [showArchived, setShowArchived] = useState(false);
  const { t } = useTranslation();
  const alert = useAlert();
  const history = useHistory();
  const Authorizer = useIAM();
  const {
    actions: { getLists, setLimit, setOffset, setSearch },
    limit,
    offset,
    error,
    loading,
    response,
    search,
  } = useContext(ListContext);

  useEffect(() => {
    getLists(showArchived);
  }, [limit, offset, search, showArchived]);

  const setSearchDebounced = debounce(setSearch, 2000);

  if (loading) return <Loading/>;
  if (error) return <div>Error...</div>;

  const archiveList = async (e, id) => {
    e.stopPropagation();

    try {
      await ListModel.archiveList(id);
      getLists();
    } catch (e) {
      alert.show(e.message);
    }
  };

  const restoreList = async (e, id) => {
    e.stopPropagation();

    try {
      await ListModel.restoreList(id);
      getLists();
    } catch (e) {
      alert.show(e.message);
    }
  };

  return (
    <>
      <Row>
        <Col className="d-flex justify-content-start align-items-center mb-2">
          <SectionHeader title={t('list.name_plural')}/>
          {Authorizer.allowed('list', 'create') && (
            <Link to={routes.CREATE_LIST} className="btn btn-light btn-sm">
              {t('common.create_new_resource', { resource: t('list.name') })}
            </Link>
          )}
        </Col>
        <Col lg={4} className={'d-flex align-items-bottom mb-2'}>
          <Button variant={'light'} size={'sm'} className={'mr-2'} onClick={() => setShowArchived(!showArchived)}>
            {t('common.archived')}: <Icon name={`eye${showArchived ? '' : '-slash'}`}/>
          </Button>
          <FormInput
            onChange={({ target: { value } }) => setSearchDebounced(value)}
            className={'mb-0'}
            name={'list_search'}
            type={'text'}
            placeholder={t('common.search')}
          />
        </Col>
      </Row>
      <Table
        showHeader
        className={'mt-2'}
        data={response.rows.map(l => ({
          [t('list.attributes.name')]: <Link to={`/list/${l.id}`}>{l.title}</Link>,
          [t('list.attributes.description')]: l.description || '-',
          [t('common.active')]: l.is_active ? (
            <Badge className="badge-success">{t('common.yes')}</Badge>
          ) : (
            <Badge className="badge-danger">{t('common.no')}</Badge>
          ),
          _link: `/list/${l.id}`,
          [t('common.actions')]: (
            <div>
              {l.is_active && Authorizer.allowed('list', 'update') && (
                <Icon
                  size={'lg'}
                  name={'pencil'}
                  style={{ marginRight: 10, cursor: 'pointer' }}
                  onClick={() => history.push(routes.EDIT_LIST.replace('id', l.id))}
                />
              )}
              {l.is_active && Authorizer.allowed('list', 'delete') && (
                <Icon
                  name={'archive'}
                  size={'lg'}
                  style={{ marginRight: 10, cursor: 'pointer' }}
                  onClick={(e) => archiveList(e, l.id)}
                />
              )}
              {!l.is_active && Authorizer.allowed('list', 'delete') && (
                <Icon
                  name={'undo'}
                  size={'lg'}
                  style={{ marginRight: 10, cursor: 'pointer' }}
                  onClick={(e) => restoreList(e, l.id)}
                />
              )}
            </div>
          ),
        }))}
      />
      <Pagination
        limit={limit}
        offset={offset}
        totalPages={response.total_pages}
        setPage={setOffset}
        setLimit={setLimit}
      />
    </>
  );
};

export default withListContext(ListsPage);
