//TODO: optimize
import RESPONSE_STATUSES from 'constants/response_statuses';
import routes from 'constants/routes';
import { register } from 'models/User';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Col, Container, FormControl, Image, InputGroup, Row } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import logo from 'styles/Logo2.png';

const RegisterPage = () => {
  const alert = useAlert();
  const { t } = useTranslation();

  const [state, setState] = useState({
    email: '',
    password: '',
    first_name: '',
    last_name: '',
  });

  const handleInput = e => {
    const { name, value } = e.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  const submit = async () => {
    const { email, password, last_name, first_name } = state;

    try {
      const { data: result } = await register(email, password, first_name, last_name);

      if (result.status === RESPONSE_STATUSES.OK) {
        window.location.pathname = '/';
      } else {
        alert.show(result.data);
      }
    } catch (e) {
      alert.show(t('common.something_goes_wrong'));
    }
  };

  return (
    <>
      <Container className="background-texture">
        <Col md={{ span: 4, offset: 4 }} className="shadow-box-login">
          <Row className="pt-5 justify-content-center">
            <Image src={logo} width={310} height={92} className="mt-5 justify-content-center"/>
          </Row>

          <Container className="pt-5 ">
            <h5 className="text-center pb-4">{t('registration_form.registration')}:</h5>
            <InputGroup className="mb-3">
              <FormControl
                placeholder={t('common.email')}
                aria-label="email"
                aria-describedby="email"
                name="email"
                value={state.email}
                onChange={handleInput}
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <FormControl
                placeholder={t('common.password')}
                aria-label="password"
                aria-describedby="password"
                name="password"
                type="password"
                value={state.password}
                onChange={handleInput}
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <FormControl
                placeholder={t('user.attributes.first_name')}
                aria-label="first_name"
                aria-describedby="first_name"
                name="first_name"
                value={state.first_name}
                onChange={handleInput}
              />
            </InputGroup>

            <InputGroup>
              <FormControl
                placeholder={t('user.attributes.last_name')}
                aria-label="last_name"
                aria-describedby="password"
                name="last_name"
                value={state.last_name}
                onChange={handleInput}
              />
            </InputGroup>

            <br/>

            <Button className={'color'} onClick={submit} block>
              {t('common.submit')}
            </Button>

            <Container className="d-flex justify-content-center">
              <Link to={routes.SLASH} className="mt-0 text-decoration-none">
                {t('registration_form.already_registered')}
              </Link>
            </Container>
          </Container>
        </Col>
      </Container>
    </>
  );
};

export default RegisterPage;
