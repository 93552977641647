import { SongSettings, TransposeSettings } from 'components/shared';
import React from 'react';

const SongSettingsList = ({ song, setChordsStyle, chordsStyle, changeTranspose, songsTranspose }) => {
  if (!song) return null;

  return (
    <>
      <SongSettings/>
      <hr/>
      <TransposeSettings
        onStyleChange={setChordsStyle}
        style={chordsStyle}
        onTransposeClick={changeTranspose(song.id)}
        value={songsTranspose[song.id]}
        songKey={song.key}
      />
    </>
  );
};

export default SongSettingsList;