import { debounce } from 'lodash';
import { getSongs } from 'models/Song';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const SongsListContext = React.createContext({});

export const SongsListProvider = ({ children }) => {
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(1);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState({ rows: [], total_pages: 0 });
  const [search, setSearch] = useState('');

  const debouncedSearch = debounce(word => setSearch(word), 2000);

  const getSongsList = (params) => {
    return getSongs({
      limit,
      offset,
      search,
      ...(params?.sortKey && { sortKey: params.sortKey }),
      ...(params?.sortOrder && { sortOrder: params.sortOrder }),
    })
      .then(response => {
        setResponse(response);
        setLoading(false);
      })
      .catch(setError);
  };

  const store = {
    actions: {
      getSongsList,
      debouncedSearch,
      setLimit,
      setOffset,
      setSearch,
    },
    limit,
    offset,
    error,
    loading,
    response,
    search,
  };

  return <SongsListContext.Provider value={store}>{children}</SongsListContext.Provider>;
};

SongsListProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const SongsListConsumer = SongsListContext.Consumer;
export const withSongsListContext = WrappedComponent => props => (
  <SongsListProvider>
    <WrappedComponent {...props} />
  </SongsListProvider>
);
export default SongsListContext;
