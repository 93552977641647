import SongForm from 'components/pages/authorized/songs/SongForm';
import { Loading } from 'components/shared';
import endpoints from 'constants/endpoints';
import { useAuthRequester } from 'helpers/requester_next';
import { updateSong } from 'models/Song';
import PropTypes from 'prop-types';
import React from 'react';
import { useAlert } from 'react-alert';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const EditSong = ({ match: { params: { id: songID } } }) => {
  const { t } = useTranslation();

  const history = useHistory();
  const alert = useAlert();

  const { data: song, loading, error } = useAuthRequester(endpoints.getSong(songID));

  if (loading) return <Loading/>;
  if (error) return <div>{t('common.error')}...</div>;

  const handleEditSong = data =>
    updateSong(songID, data)
      .then(() => history.goBack())
      .catch(({ message }) => alert.show(message));

  return (
    <Row>
      <Col className="text-left">
        <h2>{t('common.update_resource', { resource: t('songs.name') })} {song.title}</h2>
        <SongForm onChange={handleEditSong} song={song}/>
      </Col>
    </Row>
  );
};

EditSong.propTypes = {
  match: PropTypes.object,
};

export default EditSong;
