import Dropdown from 'components/shared/inputs/dropdown';
import { range } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Pagination as BSPagination } from 'react-bootstrap';
import styles from './Pagination.module.css';

const Pagination = ({ limit, offset, totalPages, setPage, setLimit, mini }) =>
  mini ? (
    <div className={styles.miniPaginationBase}>
      <BSPagination className={styles.miniPagination}>
        <BSPagination.Prev disabled={offset === 1} onClick={() => setPage(offset - 1)}/>
        <span>
          {offset} of {totalPages}{' '}
        </span>
        <BSPagination.Next disabled={offset === totalPages} onClick={() => setPage(offset + 1)}/>
      </BSPagination>
    </div>
  ) : (
    <div className={styles.paginationBase}>
      {totalPages >= 2 && (
        <BSPagination>
          {totalPages > 10 && <BSPagination.First disabled={offset === 1} onClick={() => setPage(1)}/>}
          <BSPagination.Prev disabled={offset === 1} onClick={() => setPage(offset - 1)}/>
          {totalPages < 6 &&
          range(totalPages).map(i => (
            <BSPagination.Item onClick={() => setPage(i + 1)} key={`pagination_item_${i}`} active={offset === i + 1}>
              {i + 1}
            </BSPagination.Item>
          ))}
          {totalPages > 5 && (
            <>
              {offset > 3 && <BSPagination.Ellipsis/>}
              {[offset - 2, offset - 1, offset, offset + 1, offset + 2]
                .filter(i => i > 0 && i < totalPages)
                .map(i => (
                  <BSPagination.Item onClick={() => setPage(i)} key={`pagination_item_${i}`} active={offset === i}>
                    {i}
                  </BSPagination.Item>
                ))}
              {totalPages - offset > 3 && <BSPagination.Ellipsis/>}
              <BSPagination.Item
                onClick={() => setPage(totalPages)}
                key={`pagination_item_${totalPages}`}
                active={offset === totalPages}
              >
                {totalPages}
              </BSPagination.Item>
            </>
          )}
          <BSPagination.Next disabled={offset === totalPages} onClick={() => setPage(offset + 1)}/>
          {totalPages > 10 && (
            <BSPagination.Last disabled={offset === totalPages} onClick={() => setPage(totalPages)}/>
          )}
        </BSPagination>
      )}
      <div className={styles.limitSection}>
        <span>Limit:</span>
        <Dropdown
          name={'limit'}
          value={limit}
          onChange={({ target: { value } }) => setLimit(value)}
          options={[{ value: 10, label: '10' }, { value: 15, label: '15' }, { value: 20, label: '20' }, {
            value: 30,
            label: '30',
          }]}
        />
      </div>
    </div>
  );

Pagination.propTypes = {
  limit: PropTypes.number,
  mini: PropTypes.bool,
  offset: PropTypes.number,
  setLimit: PropTypes.func,
  setPage: PropTypes.func,
  totalPages: PropTypes.number,
};

Pagination.defaultProps = {
  mini: false,
};

export default Pagination;
