const SERVER_URL = 'https://api.bandcrm.io';
// const LOCAL_URL = 'http://localhost:4000';

export const UI_URL = 'https://ui.bandcrm.io';
// export const WS_URL = 'ws://localhost:4040';
export const WS_URL = 'wss://api.bandcrm.io/ws';

export const API_URL = SERVER_URL;

export const S3Config = {
  endPoint: 'fs.bandcrm.io',
  // endPoint: '127.0.0.1',
  port: 443,
  useSSL: true,
  accessKey: 'bandcrm',
  secretKey: 'cwbe2893jd23dd89dmqwdb',
  defaultBucket: 'dev',
};
