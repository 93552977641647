import React from 'react';

class SongBody {
  constructor(order, textLines, chordLines, hintLines) {
    this.order = order;
    this._textLines = textLines;
    this._chordLines = chordLines;
    this._hintLines = hintLines;
  }

  getSongText() {
    let lines = '';

    for (let type of this.order) {
      if (type === 'blank') lines += '\n';
      if (type === 'text') lines += this._textLines.pop() + '\n';
    }

    return lines.trim().replace(/(\n{2,})/gm, '\n\n');
  }

  getSongChords() {
    return this._chordLines.join('\n');
  }

  _parseHintLine(line) {
    const [name, type] = line.replace('[', '').replace(']', '').split(',').map(x => x.trim());
    return { type: type || 'NONE', name };
  }

  toStructure() {
    const structure = [];
    for (let type of this.order) {
      if (type === 'hint') {
        const { type, name } = this._parseHintLine(this._hintLines.pop());
        structure.push({ text: [], type, name });
      }
      const lastElement = structure.at(-1);
      if (type === 'chord') lastElement.text.push(this._chordLines.pop());
      if (type === 'text') lastElement.text.push(this._textLines.pop());
    }

    return structure.map(s => ({ ...s, text: s.text.join('\n') }));
  }
}

export default SongBody;
