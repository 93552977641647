import { FormInput, SectionHeader } from 'components/shared';
import routes from 'constants/routes';
import { useIAM } from 'helpers/auth';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function SongsListHeader({ title, onChange }) {
  const { t } = useTranslation();
  const Authorizer = useIAM();

  return <Row>
    <Col className="d-flex justify-content-start align-items-center mb-2">
      <SectionHeader title={title}/>
      {Authorizer.allowed('Song', 'create') && (
        <Link to={routes.CREATE_SONG} className="btn btn-light btn-sm">
          {t('common.create_new_resource', { resource: t('songs.name') })}
        </Link>
      )}
    </Col>
    <Col lg={4}>
      <FormInput
        name={'search'}
        type={'text'}
        placeholder={`${t('common.search')}..`}
        onChange={onChange}
      />
    </Col>
  </Row>;
}

SongsListHeader.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
};

export default SongsListHeader;