import routes from 'constants/routes';
import { useIAM } from 'helpers/auth';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ListSongs = ({ songs, onSongSelect, selectedSongId }) => {
  const Authorizer = useIAM();
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column">
      {songs.sort((a, b) => a.order > b.order ? 1 : -1).map((song) => (
        <div className={'d-flex flex-row'}>
          <Button
            key={`song_${song.id}`}
            onClick={() => onSongSelect(song.id)}
            variant={selectedSongId === song.id ? 'primary' : 'light'}
            className={'d-flex mb-1 w-100 mr-1 justify-content-between'}
          >
            <span>{song.title}</span>
            <span className="badge">{song.speed}</span>
          </Button>
          {Authorizer.allowed('song', 'edit') && (
            <Link
              className={'btn btn-sm btn-light w-10 mb-1'}
              to={routes.EDIT_SONG.replace(':id', song.id)}
            >
              ✏️
            </Link>
          )}
        </div>
      ))}
      {songs.length === 0 && <span>{t('no_songs')}</span>}
    </div>
  );
};

export default ListSongs;