const CHORDS = {
  straight: {
    sharp: {
      C: 1,
      'C#': 2,
      D: 3,
      'D#': 4,
      E: 5,
      F: 6,
      'F#': 7,
      G: 8,
      'G#': 9,
      A: 10,
      'A#': 11,
      H: 12,
    },
    flat: {
      C: 1,
      Db: 2,
      D: 3,
      Eb: 4,
      E: 5,
      F: 6,
      Gb: 7,
      G: 8,
      Ab: 9,
      A: 10,
      B: 11,
      Bb: 11,
      H: 12,
    },
  },
  reversed: {
    sharp: {
      1: 'C',
      2: 'C#',
      3: 'D',
      4: 'D#',
      5: 'E',
      6: 'F',
      7: 'F#',
      8: 'G',
      9: 'G#',
      10: 'A',
      11: 'A#',
      12: 'H',
    },
    flat: {
      1: 'C',
      2: 'Db',
      3: 'D',
      4: 'Eb',
      5: 'E',
      6: 'F',
      7: 'Gb',
      8: 'G',
      9: 'Ab',
      10: 'A',
      11: 'B',
      12: 'H',
    },
  },
};

export default class Chord {
  #style;

  static testChord(s) {
    const matchedValue = s.match(/[CDEFGABH](#|##|b|bb)?/g);
    return Array.isArray(matchedValue) ? matchedValue.length : false;
  }

  constructor(chord) {
    const base = chord.match(/[CDEFGABH](#|##|b|bb)?/g)[0];
    this.type = `${chord}`.replace(base, '');
    this.#style = Chord.getStyle(chord);
    this.pitch = CHORDS.straight[this.#style][base];
  }

  get base() {
    return CHORDS.reversed[this.#style][this.pitch];
  }

  static getStyle(chord) {
    if (/(#)/.test(chord)) return 'sharp';
    if (/([bB])/.test(chord)) return 'flat';
    return 'sharp';
  }

  toString() {
    return `${this.base}${this.type}`;
  }

  getTransposedChord(offset, styleTo = this.#style) {
    const newPitch =
      this.pitch + offset > 12 ? this.pitch + offset - 12 : this.pitch + offset;

    const newChord = new Chord(this.toString());
    newChord.pitch = newPitch;
    newChord.#style = styleTo;

    return newChord;
  }
}