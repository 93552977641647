import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss';

const Table = ({ data, className, showHeader, maxHeight, onHeaderClick, sortKey, sortOrder }) => {
  const history = useHistory();
  const onHeaderClickIsSet = !!onHeaderClick;
  onHeaderClick = onHeaderClick || (() => {
  });

  if (!data[0]) return <div>No data</div>;

  const keys = Object.keys(data[0]).filter(k => k[0] !== '_');

  const handleRowClick = d => () => {
    if (d._link) history.push(d._link);
  };

  return (
    <table className={classNames(className, 'custom-table')} style={{ maxHeight }}>
      {showHeader && (
        <thead className={classNames('t-header')}>
        <tr className={classNames({ 'tr-clickable': onHeaderClickIsSet })}>
          {keys.map((k, i) => (
            <td
              key={`table_${i}`}
              onClick={() => onHeaderClick(k)}
              className={classNames(sortKey === k && (sortOrder === 'asc' ? 'up' : 'down'))}
            >
              <b>{k}</b>
            </td>
          ))}
        </tr>
        </thead>
      )}
      <tbody>
      {data.map((d, i) => (
        <tr key={`table_row_${i}`} className={classNames('custom-table-row')} onClick={handleRowClick(d)}>
          {keys.map((k, i) => (
            <td key={`data_col_${i}`}>{d[k]}</td>
          ))}
        </tr>
      ))}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  showHeader: PropTypes.bool.isRequired,
  maxHeight: PropTypes.number,
  onHeaderClick: PropTypes.func,
};

Table.defaultProps = {
  className: '',
  data: [],
  showHeader: false,
  maxHeight: null,
};

export default Table;
