import { truncate } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import link from './assets/icons8-link-24.png';
import youtube from './assets/youtube-svgrepo-com.svg';
import styles from './SongMaterials.module.scss';
import YouTubeModal from './YouTubeModal';

const getLinkIcon = (href) => {
  if (href.includes('youtu')) return youtube;
  return link;
};

const isMobile = false;

const extractVideoID = (link) => {
  const regExp = /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([^&?]+)/;
  const match = link.match(regExp);
  return match && match[1] ? match[1] : null;
};

const SongMaterials = ({ materials }) => {
  const [youtubeLink, setYoutubeLink] = useState(null);

  if (materials.length === 0) return null;

  const handleLinkClick = ({ href }) => (e) => {
    if (isMobile) return;
    if (!href.includes('youtu')) return;

    e.preventDefault();
    const videoID = extractVideoID(href);
    setYoutubeLink(videoID);
  };

  const currentMaterial = materials.find(m => m.href.includes(youtubeLink));

  return (
    <div className={styles.wrapper}>
      <YouTubeModal
        videoID={youtubeLink}
        setVideoID={setYoutubeLink}
        title={currentMaterial?.name}
      />
      <div className={'mt-2'}>
        <div className="d-flex mb-2 flex-column">
          {materials.map(material => (
            <a
              target="_blank"
              className={'btn w-100 btn-outline-primary mb-2'}
              key={`material_${material.href}`}
              href={material.href}
              onClick={handleLinkClick(material)}
            >
              <div className={'d-flex justify-content-start align-items-center'}>
                <img src={getLinkIcon(material.href)} alt="" width={18} height={18} style={{ marginRight: 10 }}/>
                <span>{material.name || truncate(material.href, { length: 30 })}</span>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

SongMaterials.propTypes = {
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      href: PropTypes.string,
    }),
  ),
};

SongMaterials.defaultProps = {
  materials: [],
};

export default SongMaterials;
