import InputField from 'components/shared/inputs/InputField';
import { useFormik } from 'formik';
import i18next from 'i18n';
import { difference, truncate } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const MaterialSchema = yup.object().shape({
  name: yup.string(),
  href: yup.string().url().required(),
});

const SongMaterialForm = ({ value, onChange, name }) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: { name: '', href: '' },
    validationSchema: MaterialSchema,
    onSubmit: newVal => {
      onChange({ target: { name, value: [...value, newVal] } });
      formik.resetForm();
    },
  });

  const handleDelete = (i) =>
    () => onChange({ target: { name, value: difference(value, [value[i]]) } });

  return (
    <div>
      <h5 className={'mb-1'}> {t('song_material.name_plural')} </h5>
      <ListGroup>
        {value.length === 0 && '-'}
        {value.map((material, i) => (
          <ListGroup.Item
            className={'d-flex justify-content-between align-items-center'}
            key={`material_material_${material.href}`}
          >
            {material.name || '...'} - {truncate(material.href, { length: 30 })}
            <Button size={'sm'} onClick={handleDelete(i)}>-</Button>
          </ListGroup.Item>
        ))}
      </ListGroup>

      <br/>

      <InputField
        hasError={!!formik.errors.name}
        name={'name'}
        label={t('song_material.attributes.name')}
        value={formik.values.name}
        onChange={formik.handleChange}
      />

      <br/>

      <InputField
        hasError={!!formik.errors.href}
        name={'href'}
        label={t('song_material.attributes.href')}
        value={formik.values.href}
        onChange={formik.handleChange}
      />

      <br/>

      <Button
        disabled={!formik.dirty || !formik.isValid}
        variant="success"
        className="btn btn-primary"
        onClick={formik.submitForm}
      >
        {t('common.add_new_resource', { resource: i18next.t('song_material.name') })}
      </Button>

    </div>
  );
};

SongMaterialForm.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, href: PropTypes.string })),
};

SongMaterialForm.defaultProps = {
  value: [],
};

export default SongMaterialForm;
