import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

const SongSettingsContext = createContext({});

export const SongSettingsProvider = ({ children }) => {
  const defaultState = {
    textSize: 12,
    cols: 2,
    chordsVisible: true,
    textsVisible: true,
    hintsVisible: true,
    textAlign: 'left',
    type: 'bubbles',
  };

  const [options, setOptions] = useState(
    window.localStorage.hasOwnProperty('songs_options') ? JSON.parse(window.localStorage.songs_options) : defaultState,
  );

  const handleChange = ({ target }) => {
    const { name, value } = target;

    setOptions({
      ...options,
      [name]: value,
    });

    //TODO: useLocalStorage
    window.localStorage.setItem(
      'songs_options',
      JSON.stringify({
        ...options,
        [name]: value,
      })
    );
  };

  const store = {
    ...options,
    handleChange,
  };

  return <SongSettingsContext.Provider value={store}>{children}</SongSettingsContext.Provider>;
};

SongSettingsProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export const SongSettingsConsumer = SongSettingsContext.Consumer;
export default SongSettingsContext;
