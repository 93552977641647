import React from 'react';
import PropTypes from 'prop-types';
import IAMContext from '../Context';

/*
Returns the IAMAuthorizer object in the function, this allows to wrap the logic
Note that at this time this would not generate the iam hints for in the UI

Usage

<IAMConsumer>
  {(IAMAuthorizer) => {
    if (IAMAuthorizer.allowed('Administration::IAM::Group', 'show')) {
     ...
    }
  }}

</IAMConsumer>
 */

const IAMConsumer = ({ children, context }) => {
  return (
    <IAMContext.Consumer>
      {({ IAMAuthorizer }) => {
        const Authorizer = IAMAuthorizer(context);
        return children(Authorizer);
      }}
    </IAMContext.Consumer>
  );
};

IAMConsumer.defaultProps = {
  context: {},
};

IAMConsumer.propTypes = {
  children: PropTypes.func.isRequired,
  context: PropTypes.object,
};

export default IAMConsumer;
