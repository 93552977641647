import { Loading, Tag } from 'components/shared';
import useTags from 'hooks/useTags';
import React, { useState } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import styles from './SongTagForm.module.scss';

const SongTagForm = ({ name, value, onChange }) => {
  const [tagName, setTagName] = useState('');
  const { tags, loading, error, addTag } = useTags();

  console.log(value);
  if (loading) return <Loading/>;
  if (error) return <div>Error...</div>;

  const handleChange = (id) => () => {
    if (!value.includes(id)) return onChange({ target: { name, value: [...value, id] } });
    if (value.includes(id)) return onChange({ target: { name, value: value.filter(v => v !== id) } });
  };

  const handleCreateTag = async () => {
    const tag = await addTag(tagName);
    handleChange(tag.id);
  };

  return (
    <div>
      <h5 className={'mb-1'}>#Tags</h5>
      <div className={styles.tagsWrapper}>
        {tags.map(tag => (
          <Tag
            onClick={handleChange(tag.id)}
            name={tag.name}
            id={tag.id}
            selected={value.includes(tag.id)}
          />
        ))}
      </div>
      <InputGroup className="mb-3 mt-2">
        <FormControl
          aria-label="tag"
          aria-describedby="tag"
          name="tag"
          value={tagName}
          onChange={({ target: { value } }) => setTagName(value)}
        />
      </InputGroup>
      <Button onClick={handleCreateTag}>+ Add Tag</Button>
    </div>
  );
};

export default SongTagForm;