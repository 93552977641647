import SongSettingsContext from 'contexts/SongSettingsContext';
import SongParser from 'helpers/song_parser/SongParser';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import Bubbles from './Bubbles';
import Column from './Column';

const Song = ({ text, transpose, style }) => {
  const songSettings = useContext(SongSettingsContext);

  if (!text) return null;

  const songBody = text ? SongParser.parse(text) : null;

  if (songBody._hintLines.length === 0 || songSettings.type === 'column')
    return <Column songBody={songBody} songSettings={{ ...songSettings, transpose, style }}/>;

  if (!songSettings.type || songSettings.type === 'bubbles')
    return <Bubbles songBody={songBody} songSettings={{ ...songSettings, transpose, style }}/>;
};

Song.propTypes = {
  text: PropTypes.string.isRequired,
  transpose: PropTypes.number,
  style: PropTypes.string,
};

Song.defaultProps = {
  transpose: 0,
};

export default Song;
