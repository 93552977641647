import SongTags from 'components/pages/authorized/songs/Song/components/SongTags';
import {
  AddToList,
  Loading,
  SectionHeader,
  Song,
  SongMaterials,
  SongSettings,
  TransposeSettings,
} from 'components/shared';
import endpoints from 'constants/endpoints';

import routes from 'constants/routes';
import { useIAM } from 'helpers/auth';
import { useAuthRequester } from 'helpers/requester_next';

import { deleteSong } from 'models/Song';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

const SongPage = props => {
  const songID = props.match.params.id;

  const alert = useAlert();
  const history = useHistory();
  const Authorizer = useIAM();
  const { t } = useTranslation();
  const [songTranspose, setSongTranspose] = useState(0);
  const [chordsStyle, setChordsStyle] = useState(undefined);
  const { data: song, loading, error } = useAuthRequester(endpoints.getSong(songID));

  if (loading) return <Loading/>;
  if (error) return <div>Error...</div>;

  const deleteThisSong = () => {
    deleteSong(songID)
      .then(() => history.push('/'))
      .catch(alert);
  };

  const changeTranspose = (value) => setSongTranspose(value);

  return (
    <Row>
      <Col md={3}>
        <div className={'d-flex flex-row align-items-center mb-3'}>
          <SectionHeader title={song.title}/>
          {Authorizer.allowed('list', 'update') && (
            <Link className={'btn btn-light btn-sm'} to={routes.EDIT_SONG.replace(':id', songID)}>
              {t('common.update')}
            </Link>
          )}
        </div>
        <div className="d-flex mb-3">
          <div className={'w-50 text-left mr-2'}>
            {t('songs.attributes.speed')} <b>{song.speed}</b>
          </div>
          <div className={'w-50 text-left'}>
            {t('songs.attributes.key')} <b>{song.key || '-'}</b>
          </div>
        </div>
        <div className={'d-flex mb-4'}>
          {Authorizer.allowed('list', 'add') && <AddToList songID={songID} className={'mr-2 w-50'}/>}
          {Authorizer.allowed('Song', 'delete') && (
            <Button variant="danger" onClick={deleteThisSong} size={'sm'} className={'w-50'}>
              {t('common.delete')}
            </Button>
          )}
        </div>
        <SongSettings/>
        <TransposeSettings
          onTransposeClick={changeTranspose}
          value={songTranspose}
          onStyleChange={setChordsStyle}
          style={chordsStyle}
          songKey={song.key}
        />
        <SongMaterials materials={song.materials}/>
        <SongTags tags={song.tags}/>
      </Col>
      <Col>
        {
          song.text && (
            <Song
              text={song.text}
              transpose={songTranspose}
              style={chordsStyle}
            />
          )
        }
      </Col>
    </Row>
  );
};

SongPage.propTypes = {
  match: PropTypes.any,
};

export default SongPage;
