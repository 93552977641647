import withBand from 'components/hocs/withBand';
import { FormInput, SectionHeader } from 'components/shared';
import { uploadFileToBucket } from 'config/S3Client';
import ApplicationContext from 'contexts/ApplicationContext';
import { useIAM } from 'helpers/auth';
import { sanitizer } from 'helpers/validator';
import { updateBand } from 'models/Band';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const BandInfo = ({ bandInfo }) => {
  const initialUserParams = {
    title: bandInfo.title,
    logo: bandInfo.logo,
  };

  const { refetchBand } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const alert = useAlert();

  const [bandParams, updateBandParams] = useState(initialUserParams);
  const updateBandLocal = params => {
    const updateParams = {
      ...bandParams,
      ...params,
    };
    updateBandParams(updateParams);
  };

  const uploadFile = ({ target: { files } }) => {
    const file = files[0];
    uploadFileToBucket(file)
      .then(logo => updateBandLocal({ logo }))
      .catch(({ message }) => {
        alert.show(message);
      });
  };

  const Authorizer = useIAM();

  const handleChange = ({ target: { name, value } }) => {
    updateBandLocal({
      [name]: value,
    });
  };

  return (
    <>
      <Row className={'mb-2'}>
        <Col className="d-flex justify-content-start align-items-start mb-2">
          <SectionHeader
            title={t('common.update_resource', { resource: t('band.name') })}
          />
        </Col>
      </Row>
      <Row>
        {Authorizer.allowed('band', 'update') && (
          <Col md={{ span: 3 }}>
            <FormInput
              name={'title'}
              label={t('band.attributes.name')}
              value={bandParams.title}
              onChange={handleChange}
            />
            {/*<FormInput type={'file'} name={'logo'} label={t('band.attributes.logo')} onChange={uploadFile}/>*/}
          </Col>
        )}
        <Col md={{ span: 2, offset: 2 }}>
          <Image src={bandParams.logo} roundedCircle fluid/>
        </Col>
      </Row>
      <Row className={'d-flex'}>
        <Col md={1}>
          {Authorizer.allowed('band', 'update') && (
            <Button
              variant="success"
              className="p-2"
              onClick={() => {
                updateBand(sanitizer(bandParams))
                  .then(() => {
                    alert.show(t('common.saved_changes'));
                    refetchBand();
                  })
                  .catch(({ message }) => {
                    alert.show(message);
                  });
              }}
            >
              {t('common.submit')}
            </Button>
          )}
        </Col>
        <Col md={1}>
          {Authorizer.allowed('band', 'update') && (
            <Button variant="info" className="p-2" onClick={() => updateBandParams(initialUserParams)}>
              {t('common.cancel')}
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

BandInfo.propTypes = {
  bandInfo: PropTypes.object.isRequired,
};

export default withBand(BandInfo);
