import classNames from 'classnames';
import Chord from 'helpers/chord';
import { chunk } from 'lodash';
import React from 'react';
import styles from './song.module.scss';


const CHORUS_SECTION_TYPES = ['chorus', 'приспів'];
const BRIDGE_SECTION_TYPES = ['bridge', 'бридж', 'брідж', 'міст', 'мост'];

const checkType = (sectionType, arr) => arr.some(type => sectionType.toLowerCase().includes(type));
const isChorusType = (sectionType = '') => checkType(sectionType, CHORUS_SECTION_TYPES);
const isBridgeType = (sectionType = '') => checkType(sectionType, BRIDGE_SECTION_TYPES);

class Section {
  constructor(type = '') {
    this.type = type.replace('[', '').replace(']', '');
    this.order = [];
  }

  addTextLine(content) {
    this.order.push({ type: 'text', content });
  }

  addBlankLine() {
    this.order.push({ type: 'blank' });
  }

  addChordsLine(content) {
    this.order.push({ type: 'chords', content });
  }

  convertToText({ textSize, chordsVisible, textsVisible, transpose, style }) {
    const song = [];

    textSize = +textSize;

    let i = 0;

    for (let { type, content } of this.order) {
      i++;

      if (type === 'blank') song.push(<br key={`blank-${i}`}/>);

      if (chordsVisible && type === 'chords') {
        song.push(
          <span key={`s_${i}_${type}`} style={{ fontSize: textSize }} className={styles.chord}>
            {
              content.split(' ')
                .map(
                  str => str.trim().length
                    ? str.split('/')
                      .map(
                        str => Chord.testChord(str)
                          ? new Chord(str).getTransposedChord(transpose, style).toString()
                          : ['(', ')', '-', ' '].includes(str)
                            ? str
                            : '[Wrong chord]',
                      ).join('/')
                    : ' ',
                )
                .join(' ')
            }
          </span>,
        );
      }
      if (textsVisible && type === 'text')
        song.push(<span className={styles.textLine} key={`s_${i}_${type}`}
                        style={{ fontSize: textSize }}>{content}</span>);
    }

    return song;
  }
}

const Bubbles = ({ songBody, songSettings }) => {
  const { textSize, chordsVisible, textsVisible, transpose, style } = songSettings;
  const [chordLines, hintLines, textLines] = [songBody._chordLines, songBody._hintLines, songBody._textLines];

  const sections = songBody.order
    .map((line, i) => line === 'hint' ? i : null)
    .filter(line => Number.isInteger(line))
    .map((start, i, arr) => songBody.order.slice(start, arr[i + 1] || songBody.order.length))
    .map(section => {
      const s = new Section(hintLines.pop());
      section.forEach(line => {
        if (line === 'blank') return s.addBlankLine();
        if (line === 'text') return s.addTextLine(textLines.pop());
        if (line === 'chord') return s.addChordsLine(chordLines.pop());
      });
      return s;
    });

  const cols = songSettings.cols || 2;

  return (
    <div className={styles.songBase}>
      {
        chunk(sections, Math.ceil(sections.length / cols)).map((sections, j) => (
          <div key={`song_section_col_${j}`} className={styles.column}
               style={{ width: `calc(100% / ${cols})`, float: 'left' }}>
            {sections.map((section, i) => (
              <div
                className={classNames(styles.section, {
                  [styles.redBorder]: isChorusType(section.type),
                  [styles.violetBorder]: isBridgeType(section.type),
                })}
                key={`song_${section.type}_${i}`}
              >
                <span className={classNames(styles.title, {
                  [styles.redTitle]: isChorusType(section.type),
                  [styles.violetTitle]: isBridgeType(section.type),
                })}>
                  {section.type}
                </span>
                <div className={styles.text}>
                  {section.convertToText({
                    textSize,
                    chordsVisible,
                    textsVisible,
                    transpose,
                    style,
                  })}</div>
              </div>
            ))}
          </div>
        ))
      }
    </div>
  );
};

export default Bubbles;