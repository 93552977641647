import { DropDown, FormInput, Loading, SectionHeader } from 'components/shared';
import { uploadFileToBucket } from 'config/S3Client';
import endpoints from 'constants/endpoints';
import { useIAM } from 'helpers/auth';
import { useAuthRequester } from 'helpers/requester_next';
import { sanitizer } from 'helpers/validator';
import { updateUser } from 'models/User';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import user from './user.png';

const Settings = ({ userInfo }) => {
  const { t, i18n } = useTranslation();

  const Authorizer = useIAM();

  const initialUserParams = {
    avatar_url: userInfo.user.avatar_url || user,
    email: userInfo.user.email,
    first_name: userInfo.user.first_name,
    last_name: userInfo.user.last_name,
    password: '',
  };

  const [userParams, updateUserParams] = useState(initialUserParams);

  const updateUserLocal = params => {
    const updateParams = {
      ...userParams,
      ...params,
    };

    updateUserParams(updateParams);
  };

  const alert = useAlert();

  const uploadFile = ({ target: { files } }) => {
    const file = files[0];

    uploadFileToBucket(file)
      .then(avatar_url => updateUserLocal({ avatar_url }))
      .catch(({ message }) => {
        alert.show(message);
      });
  };

  const handleChange = ({ target: { name, value } }) => {
    updateUserLocal({
      [name]: value,
    });
  };

  return (
    <>
      <Row>
        <Col className="d-flex justify-content-start align-items-center mb-2">
          <SectionHeader title={t('common.update_resource', { resource: t('user.name') })}/>
        </Col>
      </Row>
      <Row>
        {Authorizer.allowed('user', 'update') && (
          <Col md={6} sm={12} xs={12} className={'mb-2'}>
            <Row>
              <Col>
                <FormInput
                  name={'first_name'}
                  label={t('user.attributes.first_name')}
                  value={userParams.first_name}
                  onChange={handleChange}
                />
              </Col>
              <Col>
                <FormInput
                  name={'last_name'}
                  label={t('user.attributes.last_name')}
                  value={userParams.last_name}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <FormInput
              name={'email'}
              label={t('user.attributes.email')}
              value={userParams.email}
              onChange={handleChange}
            />
            <FormInput
              type="password"
              name={'password'}
              label={t('user.attributes.password')}
              onChange={handleChange}
            />
            <DropDown
              withoutNull
              mini
              label={t('common.language')}
              name={'language'}
              value={i18n.language}
              options={[
                { label: 'English', value: 'en' },
                { label: 'Українська', value: 'ua' },
                { label: 'Русский', value: 'ru' },
              ]}
              onChange={({ target: { value } }) => i18n.changeLanguage(value)}
            />
            {/*<Row className={'d-flex justify-content-center align-items-center'}>*/}
            {/*  <Col md={6} sm={6} xs={6}>*/}
            {/*    <FormInput*/}
            {/*      type="file"*/}
            {/*      name={'user_avatar'}*/}
            {/*      label={t('user.attributes.user_avatar')}*/}
            {/*      onChange={uploadFile}*/}
            {/*    />*/}
            {/*  </Col>*/}
            {/*  <Col md={6} sm={6} xs={6}>*/}
            {/*    <Image width={200} src={userParams.avatar_url} roundedCircle fluid/>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
          </Col>
        )}
      </Row>
      {Authorizer.allowed('user', 'update') && (
        <Row>
          <Col md={3} sm={6} xs={6}>
            <Button
              variant="success"
              className="w-100"
              onClick={() => {
                updateUser(sanitizer(userParams))
                  .then(() => {
                    alert.show(t('common.saved_changes'));
                  })
                  .catch(({ message }) => {
                    alert.show(message);
                  });
              }}
            >
              {t('common.submit')}
            </Button>
          </Col>
          <Col md={3} sm={6} xs={6}>
            <Button variant="info" className="w-100" onClick={() => updateUserParams(initialUserParams)}>
              {t('common.cancel')}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

const withData = Component => props => {
  const { data: userInfo, loading, error } = useAuthRequester(endpoints.getUserInfo);

  if (loading) return <Loading/>;
  if (error) return <div>Error...</div>;

  return <Component {...props} userInfo={userInfo}/>;
};

Settings.propTypes = {
  userInfo: PropTypes.object.isRequired,
};

export default withData(Settings);
