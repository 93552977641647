import { Modal } from 'components/shared/index';
import React from 'react';
import styles from './YouTubeModal.module.scss';

const YouTubeModal = ({ videoID, setVideoID, title }) => {
  return (
    <Modal title={title} show={videoID} onHide={() => setVideoID(null)}>
      <iframe
        className={styles.frame}
        src={`https://www.youtube.com/embed/${videoID}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Modal>
  );
};

export default YouTubeModal;