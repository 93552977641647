import { EventEmitter } from 'events';

class WSManager extends EventEmitter {
  #messagesQueue;
  #socket;
  #timer;

  constructor(URL) {
    super();

    this.URL = URL;
    this.#messagesQueue = [];
    this.#socket = null;
    this.#timer = null;
  }

  get alive() {
    return this.#socket?.readyState === 1;
  }

  #tryOpenSocket = () => {
    const wsSocket = new WebSocket(this.URL);
    wsSocket.onmessage = (message) => {
      this.emit('message', JSON.parse(message.data));
    };
    wsSocket.onerror = () => setTimeout(this.#tryOpenSocket, 1000);
    wsSocket.onclose = () => this.emit('close');
    wsSocket.onopen = () => this.emit('open');
    this.#socket = wsSocket;
  };

  #addMessageToQueue = (message) => {
    this.#messagesQueue.push(message);
  };

  /**
   * @param {Object} message
   */
  send(message) {
    if (this.alive)
      return this.#socket.send(JSON.stringify(message));

    this.#addMessageToQueue(message);
  }

  #sendPendingMessages = () => {
    if (!this.alive) return;
    if (this.#messagesQueue.length === 0) return;

    this.#messagesQueue.forEach(() => {
      const message = this.#messagesQueue.pop();
      this.send(message);
    });
  };

  start() {
    this.#tryOpenSocket();
    this.#timer = setInterval(this.#sendPendingMessages, 1000);
  }

  stop() {
    clearInterval(this.#timer);
  }
}

export default WSManager;