import axios from 'axios';
import { clearSession, prepareToken } from '../session';

export const STATUSES = {
  ok: 'ok',
  fail: 'fail',
  err: 'error',
};

const JWT_ERRORS = {
  TokenExpiredError: 'TokenExpiredError: jwt expired',
  TokenMalformed: 'jwt malformed',
};

const processFail = async ({ message }) => {
  if (message === JWT_ERRORS.TokenMalformed) {
    clearSession();
    window.location.href = '/';
  }
};

const processResponse = axiosResponse => {
  if (axiosResponse.data.status === STATUSES.ok) return axiosResponse.data.result;
  if (axiosResponse.data.status === STATUSES.fail) throw processFail(axiosResponse.data.result);
};

/**
 * @param url
 * @param headers
 * @return {Promise<*|undefined>}
 */
export const authorizedGET = async (url, headers = {}) => {
  try {
    headers['Authorization'] = prepareToken();
    const response = await axios.get(url, { headers });
    return processResponse(response);
  } catch (e) {
    throw new Error(e.message);
  }
};
/**
 * @param url
 * @param data
 * @param headers
 * @return {Promise<*|undefined>}
 */
export const authorizedPOST = async (url, data, headers = {}) => {
  try {
    headers['Authorization'] = prepareToken();
    const response = await axios.post(url, data, { headers });
    return processResponse(response);
  } catch (e) {
    throw new Error(e.message);
  }
};
/**
 * @param url
 * @param data
 * @param headers
 * @return {Promise<*|undefined>}
 */
export const authorizedPUT = async (url, data, headers = {}) => {
  try {
    headers['Authorization'] = prepareToken();
    const response = await axios.put(url, data, { headers });
    return processResponse(response);
  } catch (e) {
    throw new Error(e.message);
  }
};
/**
 * @param url
 * @param data
 * @param headers
 * @return {Promise<*|undefined>}
 */
export const authorizedPATCH = async (url, data, headers = {}) => {
  try {
    headers['Authorization'] = prepareToken();
    const response = await axios.patch(url, data, { headers });
    return processResponse(response);
  } catch (e) {
    throw new Error(e.message);
  }
};
/**
 * @param url
 * @param headers
 * @return {Promise<*|undefined>}
 */
export const authorizedDELETE = async (url, headers = {}) => {
  try {
    headers['Authorization'] = prepareToken();
    const response = await axios.delete(url, { headers });
    return processResponse(response);
  } catch (e) {
    throw new Error(e.message);
  }
};

export const authorizedGETSync = url => {
  const request = new XMLHttpRequest();

  request.open('GET', url, false);
  request.setRequestHeader('Content-Type', 'application/json');
  request.setRequestHeader('Authorization', prepareToken());
  request.send();

  if (request.status === 200) {
    const data = JSON.parse(request.responseText);
    if (data.status === STATUSES.ok) return data.result;
    if (data.status === STATUSES.fail) throw processFail(data);
  } else {
    throw new Error('Something wrong with sync GET request');
  }
};

export const unauthorizedPOSTSync = (url, data) => {
  const request = new XMLHttpRequest();

  request.open('POST', url, false);
  request.setRequestHeader('Content-Type', 'application/json');
  request.send(JSON.stringify(data));

  if (request.status === 200) {
    const data = JSON.parse(request.responseText);
    if (data.status === STATUSES.ok) return data.result;
    if (data.status === STATUSES.fail) throw processFail(data);
  } else {
    throw new Error('Something is wrong with sync POST request');
  }
};
