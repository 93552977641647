import SongBody from './SongBody';

class SongParser {
  static parse(body, options) {
    const notes = '[CDEFGABH]';
    const accidentals = '(b|bb)?';
    const chords = '(m|maj7|maj|min7|min|sus)?';
    const suspends = '(1|2|3|4|5|6|7|8|9)?';
    const sharp = '(#)?';

    const chordPattern = new RegExp('\\b' + notes + accidentals + chords + suspends + '\\b' + sharp, 'g');
    const hintPattern = /(\[.*])/;

    const order = [];
    const chordLines = [];
    const textLines = [];
    const hintLines = [];

    body.split('\n').forEach((line) => {
      line = line.trim();

      if (hintPattern.test(line)) {
        order.push('hint');
        hintLines.push(line);
      } else if (line.length < 1) {
        order.push('blank');
      } else if (chordPattern.test(line)) {
        order.push('chord');
        chordLines.push(line.trim());
      } else {
        order.push('text');
        textLines.push(line.trim());
      }
    });

    return new SongBody(order, textLines.reverse(), chordLines.reverse(), hintLines.reverse(), options);
  }
}

export default SongParser;
