import React from 'react';

import IAMContext from '../Context';
import PropTypes from 'prop-types';
import IAMAuthorizer from './IAMAuthorizer';

const IAMProvider = ({ children, statements }) => {
  const setupAuthorizer = (context = {}, contextFunc = null) => {
    return new IAMAuthorizer(statements, context, contextFunc);
  };

  return (
    <IAMContext.Provider
      value={{
        IAMAuthorizer: setupAuthorizer,
      }}
    >
      {children}
    </IAMContext.Provider>
  );
};

IAMProvider.propTypes = {
  children: PropTypes.node,
  statements: PropTypes.array,
};

export default IAMProvider;
