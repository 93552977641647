import classNames from 'classnames';
import Pagination from 'components/shared/pagination/Pagination';
import ListContext, { withListContext } from 'contexts/ListContext';
import { addSongToList } from 'models/List';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const AddToList = ({ songID, className }) => {
  const alert = useAlert();
  const { t } = useTranslation();

  const [isListsShown, setListsShown] = useState(false);

  const {
    actions: { getLists, setLimit, setOffset },
    limit,
    offset,
    response,
    search,
  } = useContext(ListContext);

  useEffect(() => {
    getLists();
  }, [limit, offset, search]);

  const addToList = listID => () => {
    addSongToList(listID, songID)
      .then(() => alert.show(t('common.saved_changes')))
      .catch(({ message }) => alert.show(message));
  };

  return (
    <div className={classNames('add-to-list', className)}>
      <Button variant={'light'} size={'sm'} className={'w-100'} onClick={() => setListsShown(!isListsShown)}>
        {t('list.add_to_list')}
      </Button>
      {isListsShown && (
        <div className={'popup'}>
          <ul>
            {response.rows.map((list, i) => (
              <li key={`list_${i}`} onClick={addToList(list.id)}>
                {list.title}
              </li>
            ))}
          </ul>
          {
            response.total_pages > 10 && (
              <Pagination
                mini
                limit={limit}
                offset={offset}
                totalPages={response.total_pages}
                setPage={setOffset}
                setLimit={setLimit}
              />
            )
          }
        </div>
      )}
    </div>
  );
};

AddToList.propTypes = {
  className: PropTypes.string,
  lists: PropTypes.array.isRequired,
  songID: PropTypes.number.isRequired,
};

export default withListContext(AddToList);
