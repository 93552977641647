import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

class TextInput extends PureComponent {
  static defaultProps = {
    clearable: false,
    grouped: true,
    hasError: false,
    mini: false,
    rows: 1,
  };
  static propTypes = {
    className: PropTypes.string,
    clearable: PropTypes.bool,
    errorMessage: PropTypes.string,
    grouped: PropTypes.bool,
    hasError: PropTypes.bool,
    hint: PropTypes.string,
    label: PropTypes.string,
    maxLength: PropTypes.number,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    rows: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  constructor(props) {
    super(props);
    this.state = {
      currentValue: props.value,
      hasError: this.props.hasError,
    };
  }

  handleChange = e => {
    const { onChange } = this.props;
    this.setState({ currentValue: e.target.value, hasError: false });
    if (onChange) {
      onChange(e);
    }
  };

  handleFocus = e => {
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(e);
    }
  };

  handleBlur = e => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(e);
    }
  };

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.hasError !== newProps.hasError) {
      this.setState({
        hasError: newProps.hasError,
      });
    }
    if (oldProps.value !== newProps.value) {
      this.setState({ currentValue: newProps.value });
    }
  }

  render() {
    const {
      label,
      hint,
      maxLength,
      required,
      name,
      onChange,
      className,
      placeholder,
      clearable,
      errorMessage,
    } = this.props;
    const { hasError, currentValue } = this.state;

    const valueToDisplay = currentValue
      ? currentValue
      : // Show '0' value
      Number.isFinite(currentValue)
        ? currentValue
        : '';

    return (
      <div
        className={classNames('form-group', 'string', 'mb-1', className, {
          clearable,
          required,
          'form-group-invalid': hasError,
        })}
      >
        {label && (
          <label className="form-control-label" htmlFor={name}>
            {label}
            {required && <abbr title={'required'}>*</abbr>}
          </label>
        )}
        <textarea
          className={classNames('form-control', {
            required,
            'is-invalid': hasError,
          })}
          name={name}
          placeholder={placeholder}
          id={name}
          cols="30"
          rows={this.props.rows}
          maxLength={maxLength}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          value={valueToDisplay}
        />

        {hint && <small className="form-text text-muted">{hint}</small>}
        {hasError && errorMessage && <small className="form-text text-danger">{errorMessage}</small>}
        {clearable && currentValue.length > 0 && (
          <i
            className={'input-clear fal fa-times fa-sm fa-fw'}
            onClick={e => {
              onChange(e);
            }}
          />
        )}
      </div>
    );
  }
}

export default TextInput;
