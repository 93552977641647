import { Loading, Song, SongMaterials, Toggle } from 'components/shared';
import { changeListState } from 'models/List';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import useList from '../withList';
import { ListHeader, ListSongs, SongSettings } from './components';
import useWSOnList from './useWSOnList';

const ListPage = (props) => {
  const listID = props.match.params.id;

  const { loading, error, list } = useList(listID);
  const [selectedSongId, setSelectedSongID] = useState(null);
  const [songsTranspose, setSongsTranspose] = useState({});
  const [chordsStyle, setChordsStyle] = useState(undefined);
  const [syncChords, setSyncChords] = useState(true);
  const [syncOrder, setSyncOrder] = useState(true);

  const {
    list: wsList,
    selectedSongID: listSelectedSongId,
    songsTranspose: listSongsTranspose,
  } = useWSOnList(listID);

  const listToUse = (syncChords && syncOrder) && Object.keys(wsList).length ? wsList : list;

  const songs = listToUse?.songs ? listToUse.songs.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}) : [];

  const selectedSongIdBackFill = (syncOrder ? listSelectedSongId : selectedSongId) || Object.keys(songs)[0];
  const selectedSong = songs[selectedSongIdBackFill];
  const songsTransposeToUse = syncChords && Object.keys(listSongsTranspose).length ? listSongsTranspose : songsTranspose;

  const selectSong = id => {
    setSelectedSongID(id);
    if (!syncOrder) return;
    return changeListState(listID, { selectedSongId: id, songsTranspose });
  };

  const changeTranspose = (songID) => (value) => {
    const transpose = { ...songsTranspose, [songID]: value };
    setSongsTranspose(transpose);
    if (!syncChords) return;
    return changeListState(listID, { selectedSongId: selectedSongIdBackFill, songsTranspose: transpose });
  };

  if (loading) return <Loading/>;
  if (error) return <div>Error...</div>;

  return (
    <Row>
      <Col md={3}>
        <ListHeader list={list}/>
        <hr/>
        <ListSongs
          songs={Object.values(songs)}
          onSongSelect={selectSong}
          selectedSongId={selectedSongIdBackFill}
        />
        <hr/>
        <div className={'d-flex align-items-start justify-content-around'}>
          <Toggle onClick={setSyncChords} value={syncChords} className={'w-50 mr-3'} label={'Sync chords'}/>
          <Toggle onClick={setSyncOrder} value={syncOrder} className={'w-50'} label={'Sync order'}/>
        </div>
        <hr/>
        <SongSettings
          song={selectedSong}
          changeTranspose={changeTranspose}
          chordsStyle={chordsStyle}
          setChordsStyle={setChordsStyle}
          songsTranspose={songsTransposeToUse}
        />
        <hr/>
        <SongMaterials materials={selectedSong?.materials}/>
      </Col>
      <Col>
        <Song
          text={selectedSong?.text}
          transpose={songsTransposeToUse[selectedSongIdBackFill] || 0}
          style={chordsStyle}
        />
      </Col>
    </Row>
  );
};

export default ListPage;
