import { Loading } from 'components/shared';
import endpoints from 'constants/endpoints';
import { useAuthRequester } from 'helpers/requester_next';
import React from 'react';

const withBand = Component => props => {
  const { data: bandInfo, loading, error } = useAuthRequester(endpoints.getBandInfo);

  if (loading) return <Loading/>;
  if (error) return <div>Error...</div>;

  return <Component {...props} bandInfo={bandInfo}/>;
};

export default withBand;
