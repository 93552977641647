import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown as BootstrapDropdown } from 'react-bootstrap';

const Dropdown = ({
                    name,
                    options,
                    value,
                    label,
                    onChange,
                    className,
                    clearable,
                    withoutNull,
                    required,
                    hasError,
                    hint,
                    errorMessage,
                    showInside,
                    variant,
                  }) => {
  const optionValue = value ? options.find(o => o.value === value) : null;

  const handleChange = value => onChange({ target: { name, value } });

  const showContent = showInside ? showInside : optionValue ? <>{optionValue.label}</> : '-';

  return (<div
    className={classNames('form-group', 'string', 'mb-1', 'mini', className, {
      clearable, required, 'form-group-invalid': hasError,
    })}
  >
    {label && (<label className="form-control-label" htmlFor={name}>
      {label}
      {required && <abbr title={'required'}>*</abbr>}
    </label>)}
    <div className={'w-100'}>
      <BootstrapDropdown>
        <BootstrapDropdown.Toggle
          className={'d-flex flex-row justify-content-center align-items-center'}
          style={{
            width: `100%`, ...(optionValue && optionValue.img ? { height: 40 } : {}),
          }}
          variant={variant || 'light'}
          size={'sm'}
        >
          {showContent}
        </BootstrapDropdown.Toggle>
        <BootstrapDropdown.Menu
          className="text-center"
          style={{ width: `100%`, overflowY: 'scroll', maxHeight: 300 }}
        >
          {!withoutNull && (<BootstrapDropdown.Item key={`dropdown_item_null`} onClick={() => handleChange(null)}>
            -
          </BootstrapDropdown.Item>)}
          {options.map((option, i) => (<BootstrapDropdown.Item
            className={'d-flex flex-row'}
            style={{ padding: '10px 10px 10px 20px' }}
            key={`dropdown_item_${i}`}
            onClick={() => handleChange(option.value)}
          >
            {option.label}
          </BootstrapDropdown.Item>))}
        </BootstrapDropdown.Menu>
      </BootstrapDropdown>
    </div>
    {hint && <small className="form-text text-muted">{hint}</small>}
    {hasError && errorMessage && <small className="form-text text-danger">{errorMessage}</small>}
  </div>);
};

Dropdown.propTypes = {
  className: PropTypes.string,
  clearable: PropTypes.bool,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  hint: PropTypes.string,
  label: PropTypes.string,
  showInside: PropTypes.any,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  value: PropTypes.any,
  withoutNull: PropTypes.bool,
};

Dropdown.defaultProps = {
  withoutNull: false,
};

export default Dropdown;
