import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const CustomModal = ({ show, onHide, onClose, onSubmit, title, children, closeText, saveText, className }) => (
  <Modal show={show} onHide={onHide} className={className} centered size={'xl'}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{children}</Modal.Body>
    <Modal.Footer>
      {onClose && (
        <Button variant="secondary" onClick={onClose}>
          {closeText}
        </Button>
      )}
      {onSubmit && (
        <Button variant="primary" onClick={onSubmit}>
          {saveText}
        </Button>
      )}
    </Modal.Footer>
  </Modal>
);

CustomModal.propTypes = {
  children: PropTypes.any.isRequired,
  closeText: PropTypes.string,
  onClose: PropTypes.func,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
  saveText: PropTypes.string,
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

CustomModal.defaultProps = {
  closeText: 'Cancel',
  saveText: 'Save',
  onHide: () => {},
};

export default CustomModal;
