import { SectionHeader } from 'components/shared';
import routes from 'constants/routes';
import { validator } from 'helpers/validator';
import { updateList } from 'models/List';
import React from 'react';
import { useAlert } from 'react-alert';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ListForm from '../ListForm';
import useList from '../withList';

const EditList = (props) => {
  const listID = props.match.params.id;
  const alert = useAlert();
  const history = useHistory();
  const { t } = useTranslation();
  const { list } = useList(listID);

  const doUpdateList = async (data) => {
    try {
      const params = {
        title: data.title,
        description: data.description,
        selected_songs: data.selectedSongs.map(s => ({ id: s.id, order: s.order })),
      };

      validator(params);

      await updateList(list.id, params);

      history.push(routes.LIST_REDIRECT(list.id));
    } catch ({ message }) {
      alert.show(message);
    }
  };

  return (
    <Container>
      <div className="text-left mb-2 p-1">
        <SectionHeader title={t('common.update_resource', { resource: t('list.name') })}/>
      </div>
      {list && <ListForm list={list} onSubmit={doUpdateList}/>}
    </Container>
  );
};

export default EditList;
