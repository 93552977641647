import Chord from 'helpers/chord';
import React from 'react';
import styles from './song.module.scss';

function getFullSong(songBody, { textSize, chordsVisible, textsVisible, transpose, style }) {
  const song = [];

  textSize = +textSize;

  const chordLines = [...songBody._chordLines];
  const textLines = [...songBody._textLines];

  let i = 0;

  for (let type of songBody.order) {
    i++;

    if (type === 'blank') {
      song.push(<p/>);
    }

    if (type === 'chord') {
      if (chordsVisible) {
        const chords = chordLines
          .pop()
          .split(' ')
          .map(
            str => str.trim().length
              ? str.split('/')
                .map(
                  str => Chord.testChord(str)
                    ? new Chord(str).getTransposedChord(transpose, style).toString()
                    : ['(', ')', '-', ' '].includes(str)
                      ? str
                      : '[Wrong chord]',
                ).join('/')
              : ' ',
          )
          .join(' ');

        song.push(
          <p key={`s_${i}_${type}`} style={{ fontSize: textSize }} className={styles.chord}>{chords}</p>,
        );
      } else {
        chordLines.pop();
      }
    }
    if (type === 'text') {
      if (textsVisible) {
        song.push(
          <p key={`s_${i}_${type}`} style={{ fontSize: textSize }} className={styles.textLine}>
            {textLines.pop()}
          </p>,
        );
      } else {
        textLines.pop();
      }
    }
  }
  return song;
}


const Column = ({ songBody, songSettings }) => {
  return (
    <div className={styles.column} style={{ textAlign: 'left' }}>
      {React.Children.toArray(getFullSong(songBody, songSettings))}
    </div>
  );
};

export default Column;