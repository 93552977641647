import {
  BandInfo,
  BandMembers,
  CreateList,
  CreateSong,
  EditList,
  EditSong,
  InviteMember,
  ListPage,
  ListsPage,
  Settings,
  SongPage,
  SongsPage,
} from 'components/pages/authorized';
import routes from 'constants/routes';
import i18next from 'i18n';

const AuthorizedRoutes = [
  {
    path: routes.SONGS,
    icon: 'music',
    title: i18next.t('songs.name_plural'),
    inSidebar: true,
    component: SongsPage,
    IAMName: 'all_songs',
  },
  {
    path: routes.SONG_ID,
    title: i18next.t('songs.name'),
    inSidebar: false,
    component: SongPage,
    IAMName: 'particular_song',
  },
  {
    path: routes.CREATE_SONG,
    title: i18next.t('common.add_new_resource', { resource: i18next.t('songs.name') }),
    inSidebar: false,
    component: CreateSong,
    IAMName: 'create_song',
  },
  {
    path: routes.EDIT_SONG,
    title: i18next.t('common.update_resource', { resource: i18next.t('songs.name') }),
    inSidebar: false,
    component: EditSong,
    IAMName: 'EditSong',
  },
  {
    path: routes.LISTS,
    title: i18next.t('list.name_plural'),
    icon: 'list',
    inSidebar: true,
    component: ListsPage,
    IAMName: 'all_lists',
  },
  {
    path: routes.BAND_MEMBERS,
    title: i18next.t('band.members'),
    icon: 'users',
    inSidebar: true,
    component: BandMembers,
    IAMName: 'band_members',
  },
  {
    path: routes.INVITE_MEMBER,
    title: i18next.t('common.add_new_resource', { resource: i18next.t('user.name') }),
    inSidebar: false,
    component: InviteMember,
    IAMName: 'invite_member',
  },
  {
    path: routes.CREATE_LIST,
    title: i18next.t('common.add_new_resource', { resource: i18next.t('list.name') }),
    inSidebar: false,
    component: CreateList,
    IAMName: 'create_list',
  },
  {
    path: routes.LIST_ID,
    title: i18next.t('list.name'),
    inSidebar: false,
    component: ListPage,
    IAMName: 'particular_list',
  },
  {
    path: routes.EDIT_LIST,
    title: i18next.t('common.update_resource', { resource: i18next.t('list.name') }),
    inSidebar: false,
    component: EditList,
    IAMName: 'update_list',
  },
  {
    path: routes.BAND_INFO,
    title: i18next.t('band.name'),
    icon: 'list-alt',
    inSidebar: true,
    component: BandInfo,
    IAMName: 'band_info',
  },
  {
    path: routes.SETTINGS,
    title: i18next.t('common.settings'),
    icon: 'cog',
    inSidebar: true,
    component: Settings,
    IAMName: 'settings',
  },
];

export default AuthorizedRoutes;
