import endpoints from 'constants/endpoints';
import { authorizedPATCH, unauthorizedPOSTSync } from 'helpers/requester_next/requester';
import { updateTokensPair } from 'helpers/session';

export const login = (email, password) => {
  const data = unauthorizedPOSTSync(endpoints.login, { email, password });
  updateTokensPair(data.accessToken, data.refreshToken);
};

export const register = async (email, password, firstName, lastName) =>
  unauthorizedPOSTSync(endpoints.register, {
    email,
    password,
    first_name: firstName,
    last_name: lastName,
  });

export const registerByInviteCode = async (inviteCode, password, firstName, lastName) =>
  unauthorizedPOSTSync(endpoints.registerByInviteCode, {
    invite_code: inviteCode,
    password,
    first_name: firstName,
    last_name: lastName,
  });

export const updateUser = async updateParams => authorizedPATCH(endpoints.updateUserInfo, updateParams);
