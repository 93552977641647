import ApplicationContext from 'contexts/ApplicationContext';
import { IAMProvider } from 'helpers/auth';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import 'styles/authorized_layout.scss';
import Header from './components/Header';

import Sidebar from './components/Sidebar';

const AuthorizedTemplate = ({ children }) => {
  const [sidebarShown, setSidebarShown] = useState(true);
  const { userStatements } = useContext(ApplicationContext);

  return (
    <IAMProvider statements={userStatements}>
      <div className="general-wrapper authorized">
        <Sidebar className={'sidebar-wrapper'} sidebarShown={sidebarShown} setSidebarShown={setSidebarShown}/>
        <div className="content-wrapper">
          <Header setSidebarShown={setSidebarShown} sidebarShown={sidebarShown}/>
          {children}
        </div>
      </div>
    </IAMProvider>
  );
};

AuthorizedTemplate.propTypes = {
  children: PropTypes.any,
};

export default AuthorizedTemplate;
