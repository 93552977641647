export const validator = object => {
  Object.keys(object).forEach(key => {
    const value = object[key];

    if (!value) throw new Error(`Validation error: '${key}' must be present`);
  });
};

export const sanitizer = object => {
  const temp = {};
  Object.entries(object).forEach(([key, value]) => {
    if (!!value) {
      temp[key] = value;
    }
  });
  return temp;
};

export default validator;
