import routes from 'constants/routes';

import SharedList from 'components/pages/universal/SharedList';

const UniversalRoutes = [
  {
    path: routes.SHARED_LIST,
    title: 'Shared List',
    component: SharedList,
  },
];

export default UniversalRoutes;
