import { Loading } from 'components/shared';
import endpoints from 'constants/endpoints';
import RESPONSE_STATUSES from 'constants/response_statuses';
import routes from 'constants/routes';
import { useAuthRequester } from 'helpers/requester_next';
import { validator } from 'helpers/validator';
import { inviteMember } from 'models/Member';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Col, Container, Dropdown, FormControl, FormGroup, InputGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const InviteMember = () => {
  const alert = useAlert();
  const history = useHistory();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [roleID, setRoleID] = useState();

  const submit = async () => {
    try {
      validator({ email, roleID });

      const result = await inviteMember(email, roleID);

      if (result.status === RESPONSE_STATUSES.FAIL) return alert.show(result.data);

      alert.show(t('band.invite_member', { email }));

      history.push(routes.BAND_MEMBERS);
    } catch ({ message }) {
      alert.show(message);
    }
  };

  const { data: roles, loading, error } = useAuthRequester(endpoints.getRoles);

  if (loading) return <Loading/>;
  if (error) return <div>Error...</div>;

  return (
    <>
      <Row>
        <Col className="col-md-12">
          <Row>
            <Col className="mx-auto col-lg-6 col-10">
              <h1>{t('common.add_new_resource', { resource: t('band.name') })}</h1>
              <Container className="text-left">
                <FormGroup>
                  <label>{t('member.attributes.email')}</label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="email">@</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="text"
                      className="form-control"
                      value={email}
                      onChange={({ target: { value } }) => setEmail(value)}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <label>Role</label>
                  <InputGroup>
                    <Dropdown>
                      <Dropdown.Toggle variant="primary">
                        {roleID
                          ? roles.find(r => r.id === roleID).name
                          : t('common.choose', { value: t('translation.member.attributes.role') })}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {roles.map((role, key) => (
                          <Dropdown.Item key={`role_${key}`} onClick={() => setRoleID(role.id)}>
                            {role.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </InputGroup>
                </FormGroup>

                <br/>
                <Button variant="success" className="btn btn-lg btn-primary" style={{ width: '100%' }} onClick={submit}>
                  {t('common.submit')}
                </Button>
              </Container>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default InviteMember;
