import { Loading } from 'components/shared';
import { WS_URL } from 'constants/application';
import endpoints from 'constants/endpoints';
import Queue from 'helpers/queue';
import { useAuthRequester } from 'helpers/requester_next';
import { authorizedGETSync } from 'helpers/requester_next/requester';
import { getParsedToken } from 'helpers/session';
import WSManager from 'helpers/ws_manager';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useRef } from 'react';

const ApplicationContext = createContext({});

export const ApplicationProvider = ({ children }) => {
  const { data: band, loading, error, refetch: refetchBand } = useAuthRequester(endpoints.getBandInfo);
  const { user_id: userID } = getParsedToken();
  const socket = useRef(new WSManager(WS_URL));
  const socketQueue = useRef(new Queue({ name: '#socket-queue' }));

  useEffect(() => {
    const subscribeToBandUpdates = (socket) => {
      socket.send({
        service: 'band-updates',
        action: 'subscribe',
        data: { bandId: band.id, userId: userID },
      });
    };

    const tryOpenSocket = () => {
      socket.current.on('message', socketQueue.current.produce);
      socket.current.on('open', () => subscribeToBandUpdates(socket.current));
      socket.current.start();
    };

    if (band?.id) tryOpenSocket();

    return () => {
      socket.current.stop();
    };
  }, [band]);

  if (loading) return <Loading/>;
  if (error) return <div>Error...</div>;

  let userStatements;
  // User Statements
  if (localStorage.hasOwnProperty('userStatements')) {
    userStatements = JSON.parse(localStorage.getItem('userStatements'));
  } else {
    // if statements doesn't exists
    userStatements = authorizedGETSync(endpoints.getUserStatements);
    localStorage.setItem('userStatements', JSON.stringify(userStatements));
  }

  const store = {
    band,
    refetchBand,
    userStatements,
    socketQueue: socketQueue.current,
    socket: socket.current,
  };

  return <ApplicationContext.Provider value={store}>{children}</ApplicationContext.Provider>;
};

ApplicationProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export const ApplicationConsumer = ApplicationContext.Consumer;
export default ApplicationContext;
