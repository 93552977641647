import AuthorizedTemplate from 'components/templates/AuthorizedTemplate';
import UnauthorizedTemplate from 'components/templates/UnauthorizedTemplate';

import { ApplicationProvider } from 'contexts/ApplicationContext';
import React from 'react';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import AuthorizedRoutes from './authorized_routes';
import UnauthorizedRoutes from './unauthorized_routes';
import UniversalRoutes from './universal_routes';

const authorizedTemplate = (
  <ApplicationProvider>
    <AuthorizedTemplate>
      <Route exact path="/">
        <Redirect to="/songs"/>
      </Route>
      {AuthorizedRoutes.map(route => (
        <Route key={`r_${route.title}`} exact {...route} />
      ))}
      {UniversalRoutes.map(route => (
        <Route key={`r_${route.title}`} exact {...route} />
      ))}
    </AuthorizedTemplate>
  </ApplicationProvider>
);

const unauthorizedTemplate = (
  <UnauthorizedTemplate>
    {UnauthorizedRoutes.map(route => (
      <Route key={`r_${route.title}`} exact {...route} />
    ))}
    {UniversalRoutes.map(route => (
      <Route key={`r_${route.title}`} exact {...route} />
    ))}
  </UnauthorizedTemplate>
);

const Router = () => {
  // TODO: make verifier
  return <BrowserRouter>{window.localStorage.access_token ? authorizedTemplate : unauthorizedTemplate}</BrowserRouter>;
};

export default Router;
