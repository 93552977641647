import classNames from 'classnames';
import { Icon } from 'components/shared';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './Header.module.scss';

const Header = ({ sidebarShown, setSidebarShown }) => (
  <div className={classNames(styles.header, { [styles.offset]: sidebarShown })}>
    <Icon name={'bars'} className={styles.icon} size={'2x'} onClick={() => setSidebarShown(!sidebarShown)}/>
    <span className={styles.title}>BandCRM</span>
  </div>
);

Header.propTypes = {
  setSidebarShown: PropTypes.func.isRequired,
  sidebarShown: PropTypes.bool.isRequired,
};

export default Header;
