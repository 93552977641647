import PropTypes from 'prop-types';
import React from 'react';

import 'styles/unauthorized_layout.scss';

const UnauthorizedTemplate = props => {
  return (
    <div className="h-100 unauthorized">
      <div className="container-fluid h-100">{props.children}</div>
    </div>
  );
};

UnauthorizedTemplate.propTypes = {
  children: PropTypes.any,
};

export default UnauthorizedTemplate;
