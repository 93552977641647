import ApplicationContext from 'contexts/ApplicationContext';
import { useCallback, useContext, useEffect, useState } from 'react';

const useWsOnList = (listId) => {
  const [list, setList] = useState({});
  const [selectedSongID, setSelectedSongID] = useState(null);
  const [songsTranspose, setSongsTranspose] = useState({});
  const { socketQueue, socket, band } = useContext(ApplicationContext);

  const handleSongChange = useCallback((song) => {
    if (!list?.songs) return;
    if (list.songs.map(s => s.id).includes(song.id)) setList({
      ...list,
      songs: list.songs.map(s => s.id === song.id ? { ...s, ...song } : s),
    });
  }, [list]);

  useEffect(() => {
    const listHandler = ({ type, data }) => {
      switch (type) {
        case 'UPDATE_LIST_STATE': {
          const { selectedSongId, songsTranspose } = data;
          setSelectedSongID(selectedSongId);
          setSongsTranspose(songsTranspose);
          break;
        }
        case 'UPDATE_LIST': {
          setList(data);
          break;
        }
        case 'UPDATE_SONG': {
          handleSongChange(data);
          break;
        }
      }
    };

    socketQueue.subscribe(listHandler);
    socket.send({ service: 'list', action: 'getState', data: { listId, bandId: band.id } });
  }, [handleSongChange]);

  return { list, selectedSongID, songsTranspose };
};

export default useWsOnList;