import { Tag } from 'components/shared';
import React from 'react';

const SongTags = ({ tags }) => {
  if (!tags.length) return null;

  return (
    <>
      <hr/>
      <div className={'mt-2'}>
        <div className="d-flex mb-2">
          {tags.map(tag => (
            <Tag name={tag.name} id={tag.id}/>
          ))}
        </div>
      </div>
    </>
  );
};

export default SongTags;