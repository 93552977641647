import ListForm from 'components/pages/authorized/list/ListForm';
import { SectionHeader } from 'components/shared';
import routes from 'constants/routes';
import { validator } from 'helpers/validator';
import { createList } from 'models/List';
import React from 'react';
import { useAlert } from 'react-alert';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const CreateList = () => {
  const history = useHistory();
  const alert = useAlert();
  const { t } = useTranslation();

  const doCreateList = async (data) => {
    try {
      const list = {
        title: data.title,
        description: data.description,
        selected_songs: data.selectedSongs.map(s => ({ id: s.id, order: s.order })),
      };

      validator(list);

      const listRequest = await createList(list);

      history.push(routes.LIST_REDIRECT(listRequest.id));
    } catch ({ message }) {
      alert.show(message);
    }
  };

  return (
    <Container>
      <Row className="text-left mb-2 p-1">
        <SectionHeader title={t('common.create_new_resource', { resource: t('list.name') })}/>
      </Row>
      <ListForm list={{}} onSubmit={doCreateList}/>
    </Container>
  );
};

export default CreateList;
