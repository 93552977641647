import Dropdown from 'components/shared/inputs/dropdown';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function transposeChord(chord = '', transposeBy) {
  chord = isEmpty(chord) ? '' : chord;
  chord = (chord === 'H' ? 'B' : chord).replace('m', '');
  const chords = ['A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#'];
  const index = chords.indexOf(chord.toUpperCase());

  if (index === -1) return '...';

  const transposedIndex = (index + transposeBy) % 12;
  return chords[transposedIndex];
}


const TransposeSettings = ({ onTransposeClick, onStyleChange, value = 0, songKey, style }) => {
  const { t } = useTranslation();

  const tryToIncrease = () => {
    if (value + 1 > 12) return onTransposeClick(0);
    onTransposeClick(value + 1);
  };

  const tryToDecrease = () => {
    if (value - 1 < 0) return onTransposeClick(12);
    onTransposeClick(value - 1);
  };

  return (
    <div className={'mt-2'}>
      <div className="d-flex justify-content-around align-items-center mb-2">
        <div className={'w-50 mr-1'}>
          <Button className="w-25 mr-1" variant="outline-primary" size={'sm'} onClick={tryToDecrease}>-</Button>
          <span className={'mr-1 btn btn-outline-primary'}>{transposeChord(songKey, value)}</span>
          <Button className="w-25" variant="outline-primary" size={'sm'} onClick={tryToIncrease}>+</Button>
        </div>
        <div className={'w-50 mt-1'}>
          <Dropdown
            withoutNull
            value={style}
            name={'style'}
            options={[
              { label: '#', value: 'sharp' },
              { label: 'b', value: 'flat' },
              { label: '-', value: undefined },
            ]}
            showInside={t('chords.style')}
            onChange={({ target: { value } }) => onStyleChange(value)}
            variant={'outline-dark'}
          />
        </div>
      </div>
    </div>
  );
};

TransposeSettings.propTypes = {
  onStyleChange: PropTypes.func.isRequired,
  onTransposeClick: PropTypes.func.isRequired,
  style: PropTypes.string,
  value: PropTypes.number,
};

TransposeSettings.defaultProps = {
  value: 0,
};

export default TransposeSettings;
