// See Ruby Implementation IAM::Authorizer
export default class IAMAuthorizer {
  constructor(statements, context = {}, contextFunc = null) {
    this.statements = statements;
    this.contextResolver = this._resolveContext(context, contextFunc);
  }

  allowed(service, action) {
    const selectedStatements = this._selectStatementFor(service, action);
    const explicitDeny = this._matchingStatementFor(selectedStatements, 'deny');

    if (explicitDeny.length < 0) return false;

    const explicitAllow = this._matchingStatementFor(selectedStatements, 'allow');
    return explicitAllow.length > 0;
  }

  _normalize = collection => {
    return Object.keys(collection).reduce((memo, key) => {
      let empty = collection[key] === null || collection[key] === undefined;
      memo[key.toLowerCase()] = empty ? '' : collection[key].toString().toLowerCase();
      return memo;
    }, {});
  };

  _selectStatementFor = (service, action) => {
    return this.statements.filter(
      statement =>
        (statement.service === '*' || statement.service === service) &&
        statement.actions.some(a => a === '*' || a === action)
    );
  };

  _matchingStatementFor = (statements, effect) => {
    return statements.filter(
      statement =>
        statement.effect === effect && statement.conditions.every(condition => this._evalCondition(condition))
    );
  };

  static OperationLambda = {
    equal: (key, values) => key === values[0],
    include: (key, values) => values.includes(key),
    exclude: (key, values) => !values.includes(key),
    not_equal: (key, values) => key !== values[0],
  };

  _evalCondition = ({ sourceKey, operator, conditionValues }) => {
    let context = this.contextResolver();
    let keyValue = context[sourceKey.toLowerCase()];
    return this.constructor.OperationLambda[operator](keyValue, conditionValues);
  };

  // Passes in the static context and functional context
  _resolveContext = (context, lazyContext) => {
    let cache = null;
    return () => {
      if (cache) {
        return cache;
      } else {
        let result = lazyContext ? lazyContext() : context;
        cache = this._normalize(result);
        return cache;
      }
    };
  };
}
