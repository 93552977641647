import { Loading, Pagination, Table, Tag } from 'components/shared';
import SongsListContext, { withSongsListContext } from 'contexts/SongsListContext';
import { debounce } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SongsListHeader from './SongsListHeader';

const keys = {
  'Song name': 'title',
  'Key': 'key',
  'Speed': 'speed',
};

const SongsPage = () => {
  const [sortKey, setSortKey] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const { t } = useTranslation();

  const {
    actions: { getSongsList, setLimit, setOffset, setSearch },
    limit,
    offset,
    error,
    loading,
    response,
    search,
  } = useContext(SongsListContext);

  useEffect(() => {
    getSongsList({ sortKey: sortKey && keys[sortKey], sortOrder });
  }, [search, limit, offset, sortOrder, sortKey]);

  const handleSortOrder = (key) => {
    if (key === sortKey)
      return setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
    setSortKey(key);
    setSortOrder('asc');
  };

  const setSearchDebounced = debounce(setSearch, 2000);

  if (loading) return <Loading/>;
  if (error) return <div>{t('common.error')}...</div>;

  return (
    <div>
      <SongsListHeader title={t('songs.name_plural')} onChange={({ target: { value } }) => setSearchDebounced(value)}/>
      <Table
        showHeader
        className={'mt-2'}
        onHeaderClick={handleSortOrder}
        sortOrder={sortOrder}
        sortKey={sortKey}
        data={response.rows.map(s => ({
          [t('songs.attributes.name')]: <Link to={`/song/${s.id}`}>{s.title}</Link>,
          [t('songs.attributes.key')]: s.key || '-',
          [t('songs.attributes.speed')]: s.speed || '-',
          Tags: s.tags.map(t => <Tag name={t.name}/>),
          _link: `/song/${s.id}`,
        }))}
      />
      <Pagination
        limit={limit}
        offset={offset}
        totalPages={response.total_pages}
        setPage={setOffset}
        setLimit={setLimit}
      />
    </div>
  );
};

export default withSongsListContext(SongsPage);
