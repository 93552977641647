import InputField from 'components/shared/inputs/InputField';
import TextInput from 'components/shared/inputs/TextInput';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import SongMaterialForm, { MaterialSchema } from './SongMaterialForm';
import SongTagForm from './SongTagForm/SongTagForm';

export const SongSchema = yup.object({
  title: yup.string().required(),
  text: yup.string().required(),
  key: yup.string().required(),
  speed: yup.string().required(),
  song_materials: yup.array().of(MaterialSchema),
  tags: yup.array().of(yup.number()),
});

const SongForm = ({ song, onChange }) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      title: song.title,
      speed: song.speed,
      key: song.key,
      text: song.text,
      materials: song.materials,
      tags: song.tags.map(({ id }) => id),
    },
    onSubmit: onChange,
    validationSchema: SongSchema,
  });

  return (
    <Form className="text-left">
      <Row>
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <InputField
            name="title"
            label={t('songs.attributes.name')}
            onChange={formik.handleChange}
            hasError={formik.errors.title}
            value={formik.values.title}
          />
          <Row>
            <Col>
              <InputField
                name="speed"
                label={t('songs.attributes.speed')}
                onChange={formik.handleChange}
                hasError={formik.errors.speed}
                value={formik.values.speed}
              />
            </Col>
            <Col>
              <InputField
                name="key"
                label={t('songs.attributes.key')}
                onChange={formik.handleChange}
                hasError={formik.errors.key}
                value={formik.values.key}
              />
            </Col>
          </Row>
          <TextInput
            name="text"
            label={t('songs.attributes.text')}
            onChange={formik.handleChange}
            value={formik.values.text}
            hasError={formik.errors.text}
            rows={20}
          />
        </Col>
        <Col md={6} lg={6} xl={6}>
          <SongMaterialForm
            name={'materials'}
            value={formik.values.materials}
            onChange={formik.handleChange}
          />
          <SongTagForm
            name={'tags'}
            value={formik.values.tags}
            onChange={formik.handleChange}
          />
        </Col>
      </Row>

      <br/>

      <Button
        disabled={!formik.isValid}
        variant="success"
        className="btn btn-primary"
        onClick={formik.submitForm}
      >
        {t('common.submit')}
      </Button>
    </Form>
  );
};

SongForm.defaultProps = {
  onChange: () => 1,
  song: {
    title: '',
    speed: '',
    key: '',
    text: '',
    song_materials: [],
    tags: [],
  },
};

SongForm.propTypes = {
  onChange: PropTypes.func,
  song: PropTypes.shape({
    title: PropTypes.string,
    speed: PropTypes.string,
    key: PropTypes.string,
    text: PropTypes.string,
    song_materials: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        href: PropTypes.string,
      }),
    ),
  }),
};

export default SongForm;
