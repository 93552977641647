import withMembers from 'components/hocs/withMembers';
import { SectionHeader, Table } from 'components/shared';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import user from './user.png';

const MembersPage = ({ members }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col className="d-flex justify-content-start align-items-center">
          <SectionHeader title={t('band.members')}/>
          {/*{Authorizer.allowed('band', 'invite_member') && (*/}
          {/*  <Link to={routes.INVITE_MEMBER} className="btn btn-light btn-sm">*/}
          {/*    {t('common.add_new_resource', { resource: t('band.member') })}*/}
          {/*  </Link>*/}
          {/*)}*/}
        </Col>
      </Row>

      <Table
        className={'mt-2'}
        data={[
          { Avatar: <b>Avatar</b>, Name: <b>Name</b> },
          ...members.map(m => ({
            Avatar: (
              <Image
                className={'mr-2'}
                src={m.avatar_url || user}
                roundedCircle
                fluid
                style={{ width: 40, height: 40 }}
              />
            ),
            Name: `${m.first_name} ${m.last_name}`,
          })),
        ]}
      />
    </>
  );
};

MembersPage.propTypes = {
  members: PropTypes.array.isRequired,
};

export default withMembers(MembersPage);
