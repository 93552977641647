import classNames from 'classnames';
import { Icon } from 'components/shared';
import ApplicationContext from 'contexts/ApplicationContext';
import { useIAM } from 'helpers/auth';
import { clearSession } from 'helpers/session';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Button, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import AuthorizedRoutes from 'router/authorized_routes';
import './styles.scss';

const Sidebar = ({ className, sidebarShown, setSidebarShown }) => {
  const Authorizer = useIAM();
  const { band } = useContext(ApplicationContext);

  const toggleSidebar = () => setSidebarShown(!sidebarShown);
  const toggleSidebarOnLinkClick = () => {
    if (document.body.offsetWidth <= 768) toggleSidebar();
  };

  return (
    <div className={classNames(className, { minimized: !sidebarShown })}>
      <div className={classNames('sidebar', { minimized: !sidebarShown })}>
        <div className={classNames('logo')}>
          <img src={band.logo} className={'rounded'} alt={'band image'}/>
          <h4 className={classNames({ 'd-none': !sidebarShown })}>{band.title}</h4>
        </div>

        <ul className="nav nav-pills">
          {Object.values(AuthorizedRoutes)
            .filter(route => route.inSidebar)
            .filter(route => Authorizer.allowed('sidebar', route.IAMName))
            .map(route => (
              <li key={route.title} className="nav-item">
                <NavLink to={route.path} className={'nav-link'} onClick={toggleSidebarOnLinkClick}>
                  <Icon name={route.icon} color={'white'} size={'lg'}/>
                  <Container className="nav-title"> {route.title} </Container>
                </NavLink>
              </li>
            ))}
        </ul>

        <hr/>

        <div className="nav nav-pills">
          <div className="nav-item">
            <Button
              className={'nav-link w-100 text-left d-flex nav-link '}
              variant={'danger'}
              onClick={() => {
                clearSession();
                window.location.href = '/';
              }}
            >
              <Icon name="unlock-alt" color="white" size="lg"/>
              <Container className="nav-title text"> Log Out </Container>
            </Button>
          </div>
        </div>
      </div>

      <div className={classNames('sidebar-opener', { minimized: !sidebarShown })} onClick={toggleSidebar}/>
    </div>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string.isRequired,
  sidebarShown: PropTypes.bool.isRequired,
  setSidebarShown: PropTypes.func.isRequired,
};

export default Sidebar;
