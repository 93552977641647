import classNames from 'classnames';
import { getISODateFromToday, getShareLink } from 'helpers/format_helpers';
import { shareList } from 'models/List';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const ShareButton = ({ listID, className }) => {
  const [isShared, setShared] = useState(false);
  const { t } = useTranslation();

  const handleShareClick = async () => {
    if (isShared) return;

    const { token } = await shareList({ list_id: listID, valid_until: getISODateFromToday(7) });
    const link = getShareLink(token);

    try {
      await navigator.clipboard.writeText(link);
      setShared(true);
    } catch (e) {
      alert(link);
    }
  };

  const cns = classNames('btn', 'btn-primary', className);
  return (
    <Button className={cns} onClick={handleShareClick}>
      {isShared ? '✅' : t('common.share')}
    </Button>
  );
};

ShareButton.propTypes = {
  className: PropTypes.string.isRequired,
  listID: PropTypes.number.isRequired,
};

ShareButton.defaultProps = {
  className: '',
};

export default ShareButton;
