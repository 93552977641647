const GeneralRoutes = {
  SLASH: '/',
  REGISTER: '/register',
  REGISTER_BY_INVITE_CODE: '/invite/:invite_code',
};

const SongsRoutes = {
  SONGS: '/songs',
  CREATE_SONG: '/create_song',
  SONG_ID: '/song/:id',
  EDIT_SONG: '/song/:id/edit',
  SONG_REDIRECT: id => `/song/${id}`,
};

const ListsRoutes = {
  LISTS: '/lists',
  CREATE_LIST: '/create_list',
  LIST_ID: '/list/:id',
  LIST_REDIRECT: id => `/list/${id}`,
  EDIT_LIST: '/list/:id/edit',
};

const BandRoutes = {
  BAND_INFO: '/band/info',
};

const BandMembers = {
  BAND_MEMBERS: '/band/members',
  INVITE_MEMBER: '/band/member',
};

const UniversalRoutes = {
  SHARED_LIST: '/share/list/:token',
};

const Settings = {
  SETTINGS: '/settings',
};


export default {
  ...GeneralRoutes,
  ...SongsRoutes,
  ...ListsRoutes,
  ...BandMembers,
  ...BandRoutes,
  ...UniversalRoutes,
  ...Settings,
};
