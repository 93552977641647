import { addTag, getTags } from 'models/Tag';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';

const useTags = () => {
  const alert = useAlert();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [tags, setTags] = useState([]);

  const refetch = () => getTags()
    .then((tags) => {
      setTags(tags);
      setLoading(false);
    })
    .catch((e) => {
      setError(e);
      setLoading(false);
    });

  const addTagFn = async (name) => {
    try {
      const tag = await addTag({ name });
      setTags([...tags, tag]);
      return tag;
    } catch (e) {
      alert(`Error: ${e.message}`);
    }
  };

  useEffect(() => {
    refetch();
  }, []);

  return { loading, error, tags, refetch, addTag: addTagFn };
};

export default useTags;