export default class Queue {
  #messages;
  #config;
  #consumers;

  /**
   * @param {Number} params.maxMessages?
   * @param {Number} params.maxProducers?
   * @param {Number} params.maxConsumers?
   * @param {boolean} params.logging?
   * @param {String} params.name
   */
  constructor(params) {
    const { maxMessages, maxProducers, maxConsumers, logging, name } = params;
    this.#messages = [];
    this.#config = {
      maxMessages: maxMessages || 1000,
      maxProducers: maxProducers || 1000,
      maxConsumers: maxConsumers || 1000,
      logging: logging || false,
    };
    this.name = name || (Math.random() * 100).toFixed(0);
    this.#consumers = [];
  }

  /**
   * @param {Function} cb
   */
  subscribe = (cb) => {
    this.#consumers.push(cb);
    if (this.#config.logging)
      console.log(`Queue${this.name}::SUBSCRIBED CONSUMER`);
  };

  /**
   * @param {Function} cb
   */
  unsubscribe = (cb) => {
    this.#consumers = this.#consumers.filter(c => c !== cb);
    if (this.#config.logging)
      console.log(`Queue${this.name}::UNSUBSCRIBED CONSUMER`);
  };

  /**
   * @param {String} message.type
   * @param {Object} message.data
   */
  #addMessage(message) {
    if (this.#messages.length > this.#config.maxMessages) this.#messages.shift();
    this.#messages.push(message);
  }

  /**
   * @param {String} type
   * @param {Object} data
   */
  produce = ({ type, data }) => {
    if (!type) throw new Error(`Message should contain type field`);
    if (!data) throw new Error(`Message should contain data field`);

    const message = { type, data };

    this.#addMessage(message);
    this.#consumers.forEach(cb => cb(message));

    if (this.#config.logging)
      console.log(`Queue${this.name}::MESSAGE JSON: ${JSON.stringify(message)}`);
  };
}