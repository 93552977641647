import routes from 'constants/routes';
import { login } from 'models/User';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';

import { Col, Container, FormControl, Image, InputGroup, Row } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import logo from '../../../../styles/Logo2.png';
import './index.scss';

const LoginPage = () => {
  const alert = useAlert();
  const { t } = useTranslation();

  const [state, setState] = useState({
    email: '',
    password: '',
  });

  const handleInput = e => {
    const { name, value } = e.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  const submit = e => {
    e.preventDefault();
    const { email, password } = state;
    try {
      login(email, password);
      window.location.href = routes.SONGS;
    } catch (e) {
      alert.show(t('common.something_goes_wrong'));
    }
  };

  return (
    <Container className="background-texture">
      <Col md={{ span: 4, offset: 4 }} className="shadow-box-login">
        <Row className="pt-5 justify-content-center">
          <Image src={logo} width={310} height={92} className="mt-5 justify-content-center"/>
        </Row>
        <Container className="pt-5">
          <Col>
            <h5 className="text-center pb-1">{t('login_form.login_to_continue')}:</h5>
          </Col>
          <br/>
          <form onSubmit={submit}>
            <InputGroup className="mb-3">
              <FormControl
                placeholder={t('common.email')}
                aria-label="email"
                aria-describedby="email"
                name="email"
                value={state.email}
                onChange={handleInput}
              />
            </InputGroup>
            <InputGroup>
              <FormControl
                placeholder={t('common.password')}
                aria-label="password"
                aria-describedby="password"
                name="password"
                type="password"
                value={state.password}
                onChange={handleInput}
              />
            </InputGroup>
            <br/>
            <input type={'submit'} className={'btn btn-block btn-primary color'} value={t('common.submit')}/>
          </form>
          <Container className="d-flex justify-content-center mt-1 display-5">
            <Link to={routes.REGISTER} className="mt-0 text-decoration-none ">
              {t('login_form.login_register_link')}
            </Link>
          </Container>
        </Container>
      </Col>
    </Container>
  );
};

export default LoginPage;
