import { Loading, SectionHeader, Song, SongSettings } from 'components/shared';
import endpoints from 'constants/endpoints';
import { useRequester } from 'helpers/requester_next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const SharedList = props => {
  const { t } = useTranslation();

  const listToken = props.match.params.token;

  const [selectedSongID, setSelectedSongID] = useState(null);

  const { data: list, loading, error } = useRequester(endpoints.getSharedList(listToken));

  if (loading) return <Loading/>;
  if (error) return <div>Error...</div>;

  const { songs } = list;

  return (
    <Row>
      <Col md={3}>
        <div className={'d-flex flex-row align-items-center'}>
          <SectionHeader title={list.title}/>
        </div>
        <div className={'d-flex mb-3'}>
          <small>{list.description}</small>
        </div>
        <div className={'text-left'}>
          <h6>List Songs</h6>
        </div>
        <hr/>
        <div className="d-flex flex-column">
          {songs.sort((a, b) => a.order > b.order).map((song, i) => (
            <Button
              key={`song_${song.id}`}
              variant={selectedSongID === i ? 'primary' : 'light'}
              onClick={() => setSelectedSongID(i)}
              className={'mb-1'}
            >
              {song.title}
            </Button>
          ))}
          {songs.length === 0 && <span>{t('no_songs')}</span>}
        </div>
        <hr/>
        <SongSettings/>
      </Col>
      <Col>
        {songs[selectedSongID] && (
          <pre>
            <Song text={songs[selectedSongID]?.text}/>
          </pre>
        )}
      </Col>
    </Row>
  );
};

SharedList.propTypes = {
  match: PropTypes.any,
};

export default SharedList;
