import PropTypes from 'prop-types';
import React from 'react';

const SectionHeader = ({ title }) => {
  return <h4 className={'mr-2 mb-0'}>{title}</h4>;
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SectionHeader;
