import LoginPage from 'components/pages/unauthorized/Login';
import RegisterPage from 'components/pages/unauthorized/Register';
import routes from '../constants/routes';

const UnauthorizedRoutes = [
  {
    path: routes.SLASH,
    title: 'Login',
    component: LoginPage,
  },
  {
    path: routes.REGISTER,
    title: 'Login',
    component: RegisterPage,
  },
];

export default UnauthorizedRoutes;
