import { SongSettingsProvider } from 'contexts/SongSettingsContext';
import React from 'react';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import Router from 'router';
import './App.scss';

import options from './config/alertOptions';

const App = () => (
  <div className="App">
    <AlertProvider template={AlertTemplate} {...options}>
      <SongSettingsProvider>
        <Router/>
      </SongSettingsProvider>
    </AlertProvider>
  </div>
);

export default App;
