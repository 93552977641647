import React from 'react';
import endpoints from 'constants/endpoints';
import { Loading } from 'components/shared';
import { useAuthRequester } from 'helpers/requester_next';

const withMembers = Component => props => {
  const { data, loading, error } = useAuthRequester(endpoints.getMembers);

  if (loading) return <Loading />;
  if (error) return <div>Error...</div>;

  return <Component {...props} members={data} />;
};

export default withMembers;
