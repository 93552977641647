import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import IAMContext from '../Context';

/*
Main usage for showing / hiding parts of the UI depending on IAM authorization

Usage

<IAM
  service={'Administration::IAM::Group'}
  action={'edit'}
  context={{ id: this.props.groupId }}>
  <h1>Content only show when authorized</h1>
</IAM>
 */

const IAM = ({ children, service, action, context }) => {
  if (!children) return null;
  return (
    <IAMContext.Consumer>
      {({ IAMAuthorizer }) => {
        const Authorizer = IAMAuthorizer(context);
        if (Authorizer.allowed(service, action)) {
          return (
            <Fragment>
              <span className={'iam-statement-hint'} iam={`${service}#${action}`}>
                {service}#{action}
              </span>
              {typeof children === 'function' ? children(Authorizer) : children}
            </Fragment>
          );
        } else {
          return (
            <span className={'iam-statement-hint'} iam={`${service}#${action}`}>
              {service}#{action}
            </span>
          );
        }
      }}
    </IAMContext.Consumer>
  );
};

IAM.defaultProps = {
  context: {},
};

IAM.propTypes = {
  children: PropTypes.node,
  service: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  context: PropTypes.object,
};

export default IAM;
