import classNames from 'classnames';
import { bool, func, string } from 'prop-types';
import React from 'react';
import styles from './Toggle.module.scss';

const ToggleComponent = ({ onClick, value, label, className }) => (
  <div className={classNames(styles.container, className)} onClick={() => onClick(!value)}>
    <div
      onClick={onClick}
      className={classNames(styles.switch, { [styles.active]: value })}
    >
      <div className={styles.toggle}/>
    </div>
    {label && <span className={styles.label}>{label}</span>}
  </div>
);

ToggleComponent.propTypes = {
  value: bool.isRequired,
  onClick: func.isRequired,
  label: string,
};

export default ToggleComponent;