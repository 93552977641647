import { API_URL } from 'constants/application';
import { authorizedDELETE, authorizedGET, authorizedPOST, authorizedPUT } from 'helpers/requester_next/requester';
import { appendParams } from 'helpers/url';

const url = `${API_URL}/list`;

export const getList = (listID) => authorizedGET(`${url}/${listID}`);
export const shareList = listShareOptions => authorizedPOST(`${API_URL}/list_share`, listShareOptions);
export const archiveList = listID => authorizedDELETE(`${url}/${listID}`);
export const restoreList = listID => authorizedPUT(`${url}/${listID}/restore`);
export const getLists = ({ unpaged = false, limit, offset, search = null, archived = false }) =>
  authorizedGET(
    appendParams(url, {
      archived: archived,
      ...(search ? { search } : {}),
      ...(unpaged ? { unpaged } : { limit, offset }),
    }),
  );
export const createList = list => authorizedPOST(url, list);
export const updateList = (id, list) => authorizedPUT(`${url}/${id}`, list);
export const addSongToList = (listID, songID) => authorizedPOST(`${url}/${listID}/song`, { song_id: songID });
export const getListState = (listID) => authorizedGET(`${url}/${listID}/state`);
export const changeListState = (listID, state) => authorizedPUT(`${url}/${listID}/state`, state);