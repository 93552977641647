import { getLists } from 'models/List';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const ListContext = React.createContext({});

export const ListProvider = ({ children }) => {
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(1);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState();
  const [search, setSearch] = useState('');

  const getListsAction = (archived = false) =>
    getLists({ limit, offset, search, archived })
      .then(response => {
        setResponse(response);
        setLoading(false);
      })
      .catch(setError);

  const store = {
    actions: {
      getLists: getListsAction,
      setLimit,
      setOffset,
      setSearch,
    },
    limit,
    offset,
    error,
    loading,
    response,
    search,
  };

  return <ListContext.Provider value={store}>{children}</ListContext.Provider>;
};

ListProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const ListConsumer = ListContext.Consumer;
export const withListContext = WrappedComponent => props => (
  <ListProvider>
    <WrappedComponent {...props} />
  </ListProvider>
);
export default ListContext;
