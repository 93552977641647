import { API_URL } from './application';

const endpoints = {
  login: `${API_URL}/user/login`,
  updateJWT: `${API_URL}/user/update_jwt`,
  register: `${API_URL}/user/register`,
  registerByInviteCode: `${API_URL}/user/register_by_invite`,

  getUserInfo: `${API_URL}/user/info`,
  getUserStatements: `${API_URL}/iam/statements`,
  updateUserInfo: `${API_URL}/user/info`,

  getBandInfo: `${API_URL}/band`,
  updateBandInfo: `${API_URL}/band`,

  getMembers: `${API_URL}/band/member`,
  inviteMember: `${API_URL}/band/member`,

  getRoles: `${API_URL}/role`,

  getSongs: `${API_URL}/song`,
  getSong: id => `${API_URL}/song/${id}`,
  createSong: `${API_URL}/song`,
  updateSong: id => `${API_URL}/song/${id}`,

  listShare: `${API_URL}/list_share`,
  getSharedList: token => `${API_URL}/list_share?token=${token}`,
  createList: `${API_URL}/list`,

  getLiveSessionGroups: `${API_URL}/live_session/group`,
  getLiveSessionGroup: id => `${API_URL}/live_session/group/${id}`,

  getLiveSessionShortcuts: `${API_URL}/live_session/shortcut`,
  getLiveSessionShortcut: id => `${API_URL}/live_session/shortcut/${id}`,
};

export default endpoints;
