import { DropDown, Toggle } from 'components/shared';
import fontSizeOptions from 'constants/font_size_options';
import SongSettingsContext from 'contexts/SongSettingsContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const SongSettings = () => {
  const { handleChange, ...options } = useContext(SongSettingsContext);

  const { t } = useTranslation();

  return (
    <div className={'mt-2'}>
      <div className="d-flex justify-content-around mb-2">
        <div className={'w-100 mr-1'}>
          <DropDown
            name={'textSize'}
            options={fontSizeOptions}
            value={options.textSize}
            onChange={handleChange}
            className={'w-100'}
            showInside={'Size'}
            withoutNull
            variant={'outline-dark'}
          />
        </div>
        <div className={'w-100 mr-1'}>
          <DropDown
            name={'type'}
            options={[{ 'label': 'Bubbles', 'value': 'bubbles' }, { 'label': 'Column', 'value': 'column' }]}
            value={options.type}
            onChange={handleChange}
            className={'w-100'}
            showInside={'Type'}
            withoutNull
            variant={'outline-dark'}
          />
        </div>
        {
          options.type === 'bubbles' && (
            <div className={'w-100'}>
              <DropDown
                name={'cols'}
                options={[1, 2, 3].map(c => ({ 'label': c, 'value': c }))}
                value={options.cols}
                onChange={handleChange}
                className={'w-100'}
                showInside={'Cols'}
                withoutNull
                variant={'outline-dark'}
              />
            </div>
          )
        }
      </div>
      <hr/>
      <div className="d-flex justify-content-around mb-2">
        <Toggle
          className={'mr-3 w-50'}
          value={options.chordsVisible}
          onClick={(value) => handleChange({ target: { name: 'chordsVisible', value } })}
          label={t('songs.settings.chords')}
        />
        <Toggle
          className={'w-50'}
          value={options.textsVisible}
          onClick={(value) => handleChange({ target: { name: 'textsVisible', value } })}
          label={t('songs.settings.text')}
        />
      </div>
    </div>
  );
};

export default SongSettings;
