import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

class InputField extends PureComponent {
  static defaultProps = {
    type: 'text',
    hasError: false,
    grouped: true,
    clearable: false,
    disabled: false,
    condensed: false,
    addButtonStyles: '',
    addButtonText: '',
  };
  static propTypes = {
    name: PropTypes.string.isRequired,
    condensed: PropTypes.bool,
    label: PropTypes.string,
    addButtonStyles: PropTypes.string,
    addButtonText: PropTypes.string,
    hint: PropTypes.string,
    maxLength: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    required: PropTypes.bool,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    autoComplete: PropTypes.string,
    pattern: PropTypes.string,
    disabled: PropTypes.bool,
    clearable: PropTypes.bool,
    withAddButton: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentValue: props.value,
      hasError: this.props.hasError,
    };
  }

  handleChange = e => {
    const { onChange } = this.props;
    this.setState({ currentValue: e.target.value, hasError: false });
    onChange(e);
  };

  handleFocus = e => {
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(e);
    }
  };

  handleBlur = e => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(e);
    }
  };

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.hasError !== newProps.hasError) {
      this.setState({
        hasError: newProps.hasError,
      });
    }
    if (oldProps.value !== newProps.value) {
      this.setState({ currentValue: newProps.value });
    }
  }

  render() {
    const {
      label,
      hint,
      maxLength,
      required,
      name,
      onChange,
      className,
      type,
      placeholder,
      disabled,
      clearable,
      errorMessage,
      pattern,
      autoComplete,
      addButtonStyles,
      addButtonText,
      withAddButton,
      onClick,
    } = this.props;
    const { hasError, currentValue } = this.state;

    const valueToDisplay = currentValue
      ? currentValue
      : // Show '0' value
      Number.isFinite(currentValue)
        ? currentValue
        : '';

    return (
      <div
        style={{ position: 'relative' }}
        className={classNames('form-group', 'string', 'mb-1', 'mini', className, {
          clearable,
          required,
          'form-group-invalid': hasError,
        })}
      >
        {label && (
          <label className="form-control-label" htmlFor={name}>
            {label}
            {required && <abbr title={'required'}>*</abbr>}
          </label>
        )}

        <input
          className={classNames('form-control', {
            required,
            'is-invalid': hasError,
          })}
          type={type}
          autoComplete={autoComplete}
          name={name}
          id={name}
          placeholder={placeholder}
          value={valueToDisplay}
          maxLength={maxLength}
          disabled={disabled}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          pattern={pattern}
        />
        {hint && <small className="form-text text-muted">{hint}</small>}
        {hasError && errorMessage && <small className="form-text text-danger">{errorMessage}</small>}
        {clearable && currentValue.length > 0 && (
          <i
            className={'input-clear fal fa-times fa-sm fa-fw'}
            onClick={e => {
              onChange(e);
            }}
          />
        )}
        {
          withAddButton && (
            <button
              className={addButtonStyles}
              onClick={() => onClick({ target: { value: valueToDisplay } })}
            >
              {addButtonText}
            </button>
          )
        }
      </div>
    );
  }
}

export default InputField;
