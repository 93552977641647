import { FormInput, Loading, SectionHeader, Table } from 'components/shared';
import Pagination from 'components/shared/pagination/Pagination';
import SongsListContext, { withSongsListContext } from 'contexts/SongsListContext';
import { clone } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const reorderFromStart = (arr) => arr.map((s, i) => ({ ...s, order: i + 1 }));

const ListForm = ({ onSubmit, list }) => {
  const { t } = useTranslation();
  const [selectedSongs, setSelectedSongs] = useState(
    reorderFromStart((list.songs || [])
      .sort((a, b) => a.order > b.order)),
  );

  const [state, setState] = useState({
    listID: list.id || null,
    title: list.title || '',
    description: list.description || '',
  });

  const {
    actions: { getSongsList, setLimit, setOffset, debouncedSearch },
    limit,
    offset,
    error,
    loading,
    response,
    search,
  } = useContext(SongsListContext);

  useEffect(() => {
    getSongsList();
  }, [limit, offset, search]);

  const handleSubmit = () => onSubmit({
    title: state.title,
    description: state.description,
    selectedSongs: reorderFromStart(selectedSongs),
  });

  const swapArrayElements = (arr, index1, index2) => {
    const temp = clone(arr);
    [temp[index1], temp[index2]] = [temp[index2], temp[index1]];
    return temp;
  };

  const handleUp = (currPos) => setSelectedSongs(swapArrayElements(selectedSongs, currPos, currPos - 1));
  const handleDown = (currPos) => setSelectedSongs(swapArrayElements(selectedSongs, currPos, currPos + 1));
  const addToList = song => () => setSelectedSongs([...selectedSongs, song]);
  const removeFromList = id => () => setSelectedSongs(selectedSongs.filter(song => song.id !== id));

  const handleSearch = (value) => {
    setOffset(1);
    debouncedSearch(value);
  };

  const handleChange = ({ target: { name, value } }) => setState({ ...state, [name]: value });

  const selectedSongsIDs = selectedSongs.map(s => s.id);
  const notSelectedSongs = response.rows.filter(s => !selectedSongsIDs.includes(s.id));

  return (
    <Row className="text-left p-1">
      <Col md={6} sm={12}>
        <FormInput
          name={'title'}
          onChange={handleChange}
          value={state.title}
          type={'text'}
          label={t('list.attributes.name')}
        />
        <FormInput
          name={'description'}
          onChange={handleChange}
          value={state.description}
          type={'text'}
          label={t('list.attributes.description')}
        />
        <SectionHeader title={'Selected songs:'}/>
        <Table
          maxHeight={500}
          data={selectedSongs.map((song, i) => ({
            actions: (
              <div>
                <Button
                  variant={'light'}
                  onClick={() => handleUp(i)}
                  disabled={i === 0}
                >
                  ▲
                </Button>
                <Button
                  variant={'light'}
                  onClick={() => handleDown(i)}
                  disabled={i === selectedSongs.length - 1}
                >
                  ▼
                </Button>
              </div>
            ),
            title: song.title,
            action: (
              <Button className="btn btn-danger btn-sm w-100" onClick={removeFromList(song.id)}>
                {t('list.remove_from_list')}
              </Button>
            ),
          }))}
        />
      </Col>
      <Col md={6} sm={12}>
        <Container className="list-group">
          <FormInput
            name={'title'}
            onChange={({ target: { value } }) => handleSearch(value)}
            type={'text'}
            label={t('common.search')}
          />
          {loading ? (
            <Loading/>
          ) : error ? (
            <div>Error...</div>
          ) : (
            <Table
              maxHeight={500}
              data={notSelectedSongs.map(song => ({
                title: song.title,
                action: selectedSongsIDs.includes(song.id) ? (
                  <Button className="btn btn-danger btn-sm w-100" onClick={removeFromList(song.id)}>
                    {t('list.remove_from_list')}
                  </Button>
                ) : (
                  <Button className="btn btn-light btn-sm w-100" onClick={addToList(song)}>
                    {t('list.add_to_list')}
                  </Button>
                ),
              }))}
            />
          )}
          <Pagination
            limit={limit}
            offset={offset}
            totalPages={response.total_pages}
            setPage={setOffset}
            setLimit={setLimit}
          />
        </Container>
        <br/>
        <Button
          variant="success"
          className="btn btn-lg btn-primary"
          style={{ width: '100%' }}
          onClick={handleSubmit}
        >
          {t('common.submit')}
        </Button>
      </Col>
    </Row>
  );
};

ListForm.propTypes = {
  list: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    songs: PropTypes.array.isRequired,
  }),
  onSubmit: PropTypes.func.isRequired,
};

ListForm.defaultProps = {
  list: {},
};

export default withSongsListContext(ListForm);
