import SongForm from 'components/pages/authorized/songs/SongForm';
import routes from 'constants/routes';
import { createSong } from 'models/Song';
import React from 'react';
import { useAlert } from 'react-alert';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const CreateSong = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const alert = useAlert();

  const handleCreateSong = (song) =>
    createSong(song)
      .then((request) => history.push(routes.SONG_ID.replace(':id', request.id)))
      .catch(({ message }) => alert.show(message));

  return (
    <Row className={'text-left'}>
      <Col className="mx-auto col-12">
        <h1>{t('common.create_new_resource', { resource: t('songs.name') })}</h1>
        <SongForm onChange={handleCreateSong}/>
      </Col>
    </Row>
  );
};

export default CreateSong;
