import endpoints from 'constants/endpoints';
import { authorizedDELETE, authorizedGET, authorizedPOST, authorizedPUT } from 'helpers/requester_next/requester';
import { appendParams } from 'helpers/url';

export const getSongs = ({ search, unpaged, limit, offset, sortKey, sortOrder }) =>
  authorizedGET(
    appendParams(endpoints.getSongs, {
      ...(search ? { search } : {}),
      ...(unpaged ? { unpaged } : { limit, offset }),
      ...(sortKey && { sort_key: sortKey }),
      ...(sortOrder && { sort_order: sortOrder }),
    }),
  );
export const createSong = song => authorizedPOST(endpoints.createSong, song);
export const updateSong = (songID, song) => authorizedPUT(endpoints.updateSong(songID), song);
export const deleteSong = songID => authorizedDELETE(endpoints.getSong(songID));
