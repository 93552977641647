import { getList } from 'models/List';
import React, { useCallback, useEffect, useState } from 'react';

const useList = (listID) => {
  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchList = useCallback(() => getList(listID)
    .then(setList)
    .catch(setError)
    .finally(() => setLoading(false)), []);

  useEffect(() => {
    fetchList();
  }, [listID, fetchList]);

  return { loading, error, list, refetch: fetchList };
};

export default useList;
