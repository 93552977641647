import endpoints from 'constants/endpoints';

const tokens = {
  access: 'access_token',
  refresh: 'refresh_token',
};

export const getAccessToken = () => {
  const token = window.localStorage.getItem(tokens.access) || window.sessionStorage.getItem(tokens.access);
  if (!token) throw new Error('No access token in storage!');
  return token;
};

export const getRefreshToken = () => {
  const token = window.localStorage.getItem(tokens.refresh) || window.sessionStorage.getItem(tokens.refresh);
  if (!token) throw new Error('No refresh token in storage!');
  return token;
};

export const updateTokensPair = (accessToken, refreshToken) => {
  window.localStorage.setItem(tokens.access, accessToken);
  window.localStorage.setItem(tokens.refresh, refreshToken);

  return { accessToken, refreshToken };
};

export const removeAccessToken = () => {
  window.localStorage.removeItem(tokens.access);
  window.sessionStorage.removeItem(tokens.access);
};

export const removeRefreshToken = () => {
  window.localStorage.removeItem(tokens.refresh);
  window.sessionStorage.removeItem(tokens.refresh);
};

export const removeIAMStatements = () => {
  window.localStorage.removeItem('userStatements');
  window.sessionStorage.removeItem('userStatements');
};

export const clearSession = () => {
  removeIAMStatements();
  removeAccessToken();
  removeRefreshToken();
};

export const renewTokenPair = () => {
  const data = {
    refresh_token: getRefreshToken(),
  };

  const request = new XMLHttpRequest();

  request.open('PATCH', endpoints.updateJWT, false);
  request.setRequestHeader('Content-Type', 'application/json');
  request.send(JSON.stringify(data));

  if (request.status === 200) {
    const data = JSON.parse(request.responseText);
    if (!data.status === 'ok') throw new Error('Something went wrong with token updates');
    const { result } = data;

    return updateTokensPair(result.accessToken, result.refreshToken);
  } else {
    throw new Error('Troubles with token renewal');
  }
};

export const getParsedToken = () => {
  const token = getAccessToken();

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');

  return JSON.parse(window.atob(base64));
};

export const prepareToken = () => {
  try {
    const parsedToken = getParsedToken();

    const isTokenExpired = +parsedToken.exp <= +`${Date.now()}`.substr(0, 10);

    if (isTokenExpired) {
      const { accessToken } = renewTokenPair();
      return accessToken;
    }

    return getAccessToken();
  } catch (e) {
    clearSession();
  }
};
