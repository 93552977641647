import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Form } from 'react-bootstrap';

import './styles.scss';

const TextInput = ({ name, type, className, value, onChange, label, hint, error, placeholder }) => (
  <>
    {label && <Form.Label className={'custom-label'}>{label}</Form.Label>}
    <Form.Group className={classNames('text-left', 'custom-input', className)}>
      <Form.Control type={type} name={name} value={value} placeholder={placeholder} onChange={onChange} />
      {hint && <Form.Text>{hint}</Form.Text>}
      {error && <Form.Text className={'text-danger'}>{hint}</Form.Text>}
    </Form.Group>
  </>
);

TextInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.any,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.any,
};

TextInput.defaultProps = {
  error: '',
  hint: '',
  label: null,
  type: 'text',
  placeholder: '',
};

export default TextInput;
