import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Loading = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Spinner animation={'border'}/>
      <br/>
      <span>{t('common.loading')}...</span>
    </div>
  );
};

export default Loading;
