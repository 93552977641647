import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Icon = ({ name, size, color, className, ...rest }) => {
  return (
    <i
      {...rest}
      aria-hidden={true}
      className={classNames('fa', className, { [`text-${color}`]: color }, `fa-${name}`, { [`fa-${size}`]: size })}
    />
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
};

export default Icon;
