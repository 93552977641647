import { SectionHeader } from 'components/shared';
import routes from 'constants/routes';
import { useIAM } from 'helpers/auth';
import { archiveList } from 'models/List';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import ShareButton from './ShareButton';

const ListHeader = ({ list }) => {
  const Authorizer = useIAM();
  const { t } = useTranslation();
  const history = useHistory();

  const handleDeleteList = () => archiveList(list.id).then(() => history.push(routes.LISTS));

  return (
    <>
      <div className={'d-flex flex-row align-items-center'}>
        <SectionHeader title={list.title}/>
        {Authorizer.allowed('list', 'update') && (
          <Link className={'btn btn-light btn-sm'} to={routes.EDIT_LIST.replace(':id', list.id)}>
            {t('common.update')}
          </Link>
        )}
      </div>
      <div className={'d-flex mb-3'}>
        <small>{list.description}</small>
      </div>
      <div className={'d-flex mb-4'}>
        {Authorizer.allowed('list', 'delete') && (
          <Button className="btn btn-danger col mr-1" onClick={handleDeleteList}>
            {t('common.delete')}
          </Button>
        )}
        {Authorizer.allowed('list', 'share') && <ShareButton listID={list.id} className={'col'}/>}
      </div>
    </>
  );
};

export default ListHeader;