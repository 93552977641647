import useAxios from 'axios-hooks';

const useRequester = (url, method = 'GET', data) => {
  const [{ data: axiosData, loading, error }, refetch] = useAxios(
    {
      url,
      method,
      data,
    },
    { useCache: false }
  );

  if (loading === false) {
    if (axiosData.status === 'error') {
      return { data: undefined, loading, error: data.result, refetch };
    }

    return { data: axiosData.result, loading, error: undefined, refetch };
  }

  return { data: undefined, loading, error, refetch };
};

export default useRequester;
